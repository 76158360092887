import { useState } from "react";
import Modal, { ModalState } from "../../shared/Modal";
import { formatNumber, formatDate } from "../helpers";
import "./TablePerceptions.css";
import StatusBadge from "../../shared/StatusBadge";
interface ShowBillReportReportRetentionProps {
    modalState: ModalState;
    closeModalCallback: () => void;
    urlReport: string;
}

interface TableRowProps {
    cpes: CpesPerceptions;
    setModalOpenShowItems: (newState: ModalState) => void;
    setReportSelected: (report: CpesPerceptions) => void;
}

const ShowItemsModal = ({ modalState, closeModalCallback, urlReport }: ShowBillReportReportRetentionProps) => {
    return (
        <Modal modalState={modalState} typeModal="generic" modalWidth="w-5/6" showAlertIcon={false} onClose={closeModalCallback}>
            <div className="min-h-[450px] flex items-center justify-center mx-auto">
                <img src={urlReport}
                     alt="reporte" className="flex-none"/>
            </div>
        </Modal>
    );
};

const TableHeader = ({ columns }:{ columns: any[]}) => {
    return (
        <thead className="header-table">
            <tr>
                {columns.map((column, index) => (
                    <th key={index} className={`header-table_th header-table_th-${index + 1} text-left tracking-wider ${column.className ?? ''}`}>
                        <p className="f-secondary font-medium text-[13px]">{ column.label }</p>
                    </th>
                ))}
            </tr>
      </thead>
    );
};

const TableRow = ({ cpes, setReportSelected, setModalOpenShowItems }: TableRowProps) => {
    const onClickShowBill = (cpeSelected: CpesPerceptions) => {
        setModalOpenShowItems(ModalState.OPEN);
        setReportSelected(cpeSelected);
    };

    return (
        <>
            <tr className="bg-white border-b">

                <td className="body_row-th f-secondary">
                    { cpes.perception_regime_description }
                </td>
                <td className="body_row-th f-secondary">
                    { cpes.document_number }
                </td>
                <td className="body_row-th f-secondary">
                    { formatDate(cpes.issue_date) }
                </td>
                <td className="body_row-th f-secondary">
                    { cpes.ruc_supplier }
                </td>
                <td className="body_row-th f-secondary">
                    { cpes.ruc_customer }
                </td>
                <td className="body_row-th f-secondary">
                    s/ { formatNumber(cpes.perception_amount) }
                </td>
                <td className="body_row-th f-secondary">
                    <StatusBadge text={cpes.state} color={cpes.state === 'Emitido' ? 'green' : 'orange'} />
                </td>

                <td className="body_row-th f-secondary text-center">
                    {   
                        cpes.link
                        ? <span className="item_link font-semibold f-secondary" onClick={() => onClickShowBill(cpes)}>Ver comprobante</span>
                        : <span className="text-gray-400 font-semibold f-secondary">Ver comprobante</span>
                    }                    
                </td>
            </tr>
            
        </>
    );
}

function TablePerceptions({ cpesPerceptions }: { cpesPerceptions: CpesPerceptions[] }) {
    const [ isModalOpenShowItems, setModalOpenShowItems] = useState<ModalState>(ModalState.CLOSE);
    const [reportSelected, setReportSelected] = useState<CpesPerceptions | null>(null);

    const columnsHeader = [
        { label: "Régimen de percepción" },
        { label: "Número de documento" },
        { label: "Fecha de emisión" },
        { label: "RUC Emisor" },
        { label: "N° de RUC del receptor" },
        { label: "Importe de percepción" },
        { label: "Estado" },
        { label: "" }
    ];

    return (
        <div className="my-5">
            <div className="grid grid-cols-table">
                <table className="max-w-full w-full text-sm text-left rtl:text-right text-gray-500">
                    <TableHeader columns={columnsHeader} />
                    <tbody>
                        {
                            cpesPerceptions.map((cpes: CpesPerceptions, i: number) => (
                                <TableRow
                                    key={'cpes-perception-row_' + i}
                                    cpes={cpes}
                                    setModalOpenShowItems={setModalOpenShowItems}
                                    setReportSelected={setReportSelected}
                                />
                            ))
                        }
                    </tbody>
                </table>

                <ShowItemsModal
                    modalState={isModalOpenShowItems}
                    closeModalCallback={() => setModalOpenShowItems(ModalState.CLOSE)}
                    urlReport={reportSelected?.link ?? ''}
                />
            </div>
        </div>
    );
}

export default TablePerceptions;
