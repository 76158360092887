import * as Yup from "yup";
import Loader from "../Loader";
import Headerauth from "./components/Header";
import { useAuthStore } from "../store/AuthStore";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomInput from "./components/CustomInput";
import { useState } from "react";
import { Link } from "react-router-dom";
import emailImg from "/assets/ic_send_email.svg?url";

interface RecoverPswIdentity {
    email: string;
}

const validationSchema = Yup.object().shape({
    email: Yup.string().required("Ingrese su correo electrónico").email("Ingrese un correo válido"),
});

const EmailSendedSuccess = () => {
    return (
        <div className="flex sm:my-auto items-start md:items-center justify-center w-full">
            <div className="container">
                <div className="px-5 mt-8 sm:mt-0 sm:px-8 sm:card-form max-w-xl border-gray sm:flex sm:min-h-[354px] justify-center flex-col">
                    <div className="text-center">
                        <img className="inline-block" src={emailImg} alt="Correo enviado" />
                    </div>

                    <div className="text-center mt-4">
                        <h1 className="font-bold text-2xl">Correo enviado con éxito</h1>
                        <p className="text-sm mt-2">Revisa tu bandeja de entrada y sigue las instrucciones.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

function RecoverPswIdentity() {
    const [ sendEmailSuccess, setSendEmailSuccess ] = useState(false);
    const sendEmailToRecoverPassword = useAuthStore(state => state.sendEmailToRecoverPassword);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
    } = useForm<RecoverPswIdentity>({
        resolver: yupResolver(validationSchema),
        mode: 'onBlur'
    });

    const onSubmit = (data: RecoverPswIdentity) => {
        sendEmailToRecoverPassword(data.email)
            .then(() => {
                console.log('Send email success');
                setSendEmailSuccess(true);
            })
            .catch((_) => {
                console.error("Error sending email...");
                setError('email', {
                    type: '404',
                    message: 'El correo no existe en nuestra base.'
                })
                setSendEmailSuccess(false);
            });
    };

    return (
        <main className="min-h-screen flex flex-col w-full login_container">
            <Loader />

            <Headerauth></Headerauth>
            <div className="flex sm:my-auto items-start md:items-center justify-center w-full">
                <div className="container">

                <div className="max-w-xl mx-auto mb-3 mt-3 sm:mt-0">
                    <Link id="btn-back-login" to={sendEmailSuccess ? '/recuperar-contraseña-identificacion' : '/login'} onClick={() => setSendEmailSuccess(false) } className="flex items-center">
                        <img src="/assets/ic_angle_left.svg" alt="Ir" />
                        <span className="text-[#1B68FF] text-sm pl-4">Volver</span>
                    </Link>
                </div>

                {
                    sendEmailSuccess ?
                        <EmailSendedSuccess />
                        : 
                        <form
                            action="#"
                            onSubmit={handleSubmit(onSubmit)}
                            method="POST"
                            className="px-5 mt-5 sm:mt-0 sm:px-8 sm:card-form max-w-xl border-gray block"
                        >
                            <div className="text-center">
                                <h1 className="text-2xl font-bold mb-1">Recupera tu cuenta</h1>
                                <p className="text-sm mb-3">Escribe tu correo y te enviaremos instrucciones para recuperar tu cuenta.</p>
                            </div>
                            <CustomInput<RecoverPswIdentity>
                                id="email"
                                labelInput="Correo"
                                type="text"
                                placeholder="user@gmail.com"
                                register={register}
                                errorMessage={errors.email?.message}
                            />

                            <div className="mt-5">
                                <button id="btn-submit-email" type="submit" className="w-full primary-button-2 py-3 px-4">
                                    Enviar correo
                                </button>
                            </div>
                        </form>
                }
            </div>
            </div>
        </main>
    );
}

export default RecoverPswIdentity;
