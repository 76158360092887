import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useDashboardStore } from "@/store/DashboardStore";

interface FilterPeriodSelectProps {
  onChange?(value: string): void; 
}

/**
 * 
 * Se recomienda usar `onChange` solo si realmente lo necesitas.
 * Recuerda que el valor de `tax_period` lo puedes obtener desde el store y agregarlo como dependencia en el efecto que hace la petición al servidor.
 * Solo usar si necesitas ejecutar alguna acción específica
 */
export default function FilterPeriodSelect({ onChange }: FilterPeriodSelectProps) {
  const { 
    filters, 
    valuesFilterSelected, 
    setValuesFilterSelected 
  } = useDashboardStore((state) => state);
  
  const handleSelectChange = (value: string): void => {
    setValuesFilterSelected({ ...valuesFilterSelected, tax_period: value });
    onChange && onChange(value);
  };

  return (
    <Select value={valuesFilterSelected.tax_period} onValueChange={handleSelectChange}>
      <SelectTrigger className="w-fit">
        <SelectValue placeholder="Periodo" />
      </SelectTrigger>
      <SelectContent align="end">
        {filters.tax_periods.map((period) => (
          <SelectItem key={period} value={period}>
            {period}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}
