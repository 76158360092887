import * as React from 'react';
import { cn } from '@/lib/utils';
import { cva, VariantProps } from 'class-variance-authority';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    VariantProps<typeof inputVariants> {
  icon?: React.ReactNode;
  containerClassName?: string;
}

const inputVariants = cva(
  'flex h-10 w-full py-2 outline-none disabled:cursor-not-allowed disabled:opacity-50',
  {
    variants: {
      variant: {},
    },
    defaultVariants: {},
  },
);

const containerVariants = cva('flex items-center rounded-xl border bg-background px-3', {
  variants: {
    variant: {
      default: 'border-gray-400',
      error: 'border-red-500',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, containerClassName, type, icon, variant, ...props }, ref) => {
    return (
      <div className={cn(containerVariants({ variant }), containerClassName)}>
        <input
          type={type}
          className={cn(inputVariants({ variant }), className)}
          ref={ref}
          {...props}
        />
        {icon}
      </div>
    );
  },
);
Input.displayName = 'Input';

export { Input };
