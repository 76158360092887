export function calculateDaysPassed(daysPassed: string) {
    const daysPassedToDate = new Date(daysPassed);
    const actualDate = new Date();

    const differenceMs = actualDate.getTime() - daysPassedToDate.getTime();
    return Math.floor(differenceMs / (1000 * 60 * 60 * 24));
}

export function isAfterToday(dateString: string): boolean {
    const date = new Date(dateString);
    const today = new Date();
  
    return date.getTime() > today.getTime();
  }