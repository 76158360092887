import { useNavigate } from "react-router-dom";
import ic_metric_up from "/assets/ic_metric_up.svg?url";
import ic_clock_red from "/assets/ic_clock_red.svg?url";
import { eventGa4Service } from '../ga4.service.tsx';
import { CONTAPRO_ANALYTICS } from '../shared/analytics.tsx';
import { getDaysUntilEndDate } from "./helpers.ts";

type PlanType = 'free' | 'expired_plan';

interface PlanTypeAlertProps {
    planType: PlanType;
    endDate: string | undefined
}

const setLabelsByTypePlan = (planType: PlanType, endDate?: string) => {
    const remainingDays = getDaysUntilEndDate(endDate)

    switch (planType) {
        case "free":
            return {
                title: "Sigue ahorrando tiempo con Contapro",
                subtitle: `Tienes ${remainingDays} día(s) de prueba gratis. Para registrar más empresas y recibir toda la información escoge uno de nuestros planes.`,
                buttonLabel: "Más información",
                buttonLink: '/planes',
                icon: ic_metric_up,
                bgCard: 'bg-[#EAFEFF]'
            };
        case "expired_plan":
            return {
                title: "Tu plan ha caducado",
                subtitle: "Para registrar más empresas y recibir las propuestas de declaración escoge uno de nuestros planes.",
                buttonLabel: "Renovar plan",
                buttonLink: '/planes',
                icon: ic_clock_red,
                bgCard: 'bg-[#FFD5DA]'
            };
        default:
            return {
                title: "¡Bienvenido a ContaPro!",
                subtitle: "Tu plan es gratuito, puedes empezar a utilizar todas las funcionalidades de ContaPro.",
                buttonLabel: "Más información",
                buttonLink: ''
            };
    }
}

function PlanTypeAlert({ planType, endDate }: PlanTypeAlertProps) {
    const navigate = useNavigate();

    const handleNavigate = (link: string, planType: 'free' | 'expired_plan') => {
        if (planType === 'free') {
            eventGa4Service({
                action: CONTAPRO_ANALYTICS.HOME.ALERT_FREE_PLAN.ACTION,
            });
        }

        if (planType === 'expired_plan') {
            eventGa4Service({
                action: CONTAPRO_ANALYTICS.HOME.ALERT_EXPIRED_PLAN.ACTION,
            });
        }

        navigate(link, {
            state: {
                planType
            }
        });
    };

    return (
        <div className={`rounded-lg px-6 py-5 flex gap-4 ${setLabelsByTypePlan(planType).bgCard}`}>
            <div className="flex-none w-10 h-auto">
                <img src={setLabelsByTypePlan(planType).icon} alt="ContaPro" className="w-16 h-auto"/>
            </div>
            <div className="">
                <p className="text-lg font-bold mb-2">{ setLabelsByTypePlan(planType).title }</p>
                <p className="text-sm mb-3 f-secondary font-normal">{ setLabelsByTypePlan(planType, endDate).subtitle }</p>
                
                <button
                    onClick={ () => handleNavigate(setLabelsByTypePlan(planType).buttonLink, planType) }
                    className="rounded-[20px] f-secondary font-medium text-base mb-3 sm:mb-0 px-5 py-2 bg-white border border-transparent hover:border-stone-950"
                    type="button"
                >{ setLabelsByTypePlan(planType).buttonLabel }</button>
            </div>
        </div>
    );
}

export default PlanTypeAlert;
