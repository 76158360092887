import { ModalState } from "@/shared/Modal";
import { useState } from "react";
import ValidateOneInvoiceModal from "./ValidateOneInvoiceModal";
import { useDashboardStore } from "@/store/DashboardStore";

const ActionsRejectedRowCell = ({
  cpe,
  currentPage,
  pageSize,
}: {
  cpe: Cpes;
  currentPage: number;
  pageSize: number;
}) => {
  const [isModalOpenValidateReport, setModalOpenValidateReport] = useState<ModalState>(
    ModalState.CLOSE,
  );
  const { fetchDashboardReportsRejected } = useDashboardStore((state) => state);
  const validateReport = () => {
    setModalOpenValidateReport(ModalState.OPEN);
  };

  const handleOnClickCloseModalValidate = () => {
    if (isModalOpenValidateReport === ModalState.DONE) {
      fetchDashboardReportsRejected(currentPage, pageSize);
    }
    setModalOpenValidateReport(ModalState.CLOSE);
  };

  return (
    <div className="actions flex gap-3 max-w-fit">
      <button
        className="bg-success-light shrink-0 w-[76px] rounded-[20px] px-3 py-2 font-xs text-success-dark font-bold"
        onClick={() => validateReport()}
      >
        Validar
      </button>
      <ValidateOneInvoiceModal
        modalState={isModalOpenValidateReport}
        setModalState={setModalOpenValidateReport}
        closeModalCallback={() => handleOnClickCloseModalValidate()}
        cpes={cpe}
      />
    </div>
  );
};

export default ActionsRejectedRowCell;
