import { Checkbox } from "@/components/ui/checkbox";
import { TableColumn } from "@/components/ui/full-table";
import { formatDate, formatNumber } from "@/dashboard/helpers";
import { useCallback, useMemo, useState } from "react";
import ObservationHeaderCell from "../components/ObservationHeaderCell";
import ActionsRejectedRowCell from "../components/ActionsRejectedRowCell";

export default function useTableRejectedColumns(
  tableRows: any[],
  currentPage: number,
  pageSize: number,
) {

  const [selectedRows, setSelectedRows] = useState<Cpes[]>([]);

  const handleCheckboxChange = useCallback(
    (cpe: Cpes) => {
      if (selectedRows.some(row => row.code == cpe.code)) {
        setSelectedRows(selectedRows.filter((row) => row.code !== cpe.code));
      } else {
        setSelectedRows([...selectedRows, { ...cpe }]);
      }
    },
    [selectedRows],
  );

  const handleSelectAllChange = useCallback(
    (selectAll: boolean) => {
      if (!selectAll) {
        setSelectedRows([]);
      } else {
        setSelectedRows(tableRows);
      }
    },
    [selectedRows, tableRows],
  );

  const columns: TableColumn[] = useMemo(() => {
    return [
      {
        label: () => (
          <Checkbox
            checked={selectedRows.length === tableRows.length}
            onCheckedChange={handleSelectAllChange}
          />
        ),
        key: "select",
        render: (cpe) => (
          <Checkbox
            checked={selectedRows.some(row => row.code === cpe.code)}
            onCheckedChange={() => handleCheckboxChange(cpe)}
          />
        ),
      },
      {
        label: "Fecha de emisión",
        key: "issue_date",
        render: ({ issue_date }) => `${formatDate(issue_date)}`,
      },
      {
        label: "Serie - Número",
        key: "serie",
        render: ({ serie, number }) => `${serie} - ${number}`,
      },
      {
        label: "Nombre/Razón social del proveedor",
        key: "issuer_business_name",
      },
      {
        label: "Total IGV",
        key: "igv_amount",
        render: ({ igv_amount }) => `s/ ${formatNumber(igv_amount)}`,
      },
      {
        label: () => <ObservationHeaderCell />,
        key: "observation",
      },
      {
        label: "",
        key: "acciones-rechazadas",
        render: (cpe) => (
          <ActionsRejectedRowCell cpe={cpe} currentPage={currentPage} pageSize={pageSize} />
        ),
      },
    ];
  }, [selectedRows, tableRows]);

  return {
    columns,
    selectedRows,
    setSelectedRows,
    handleSelectAllChange,
  };
}
