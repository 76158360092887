import { Checkbox } from "@/components/ui/checkbox";
import { TableColumn } from "@/components/ui/full-table";
import { formatNumber } from "@/dashboard/helpers";
import { useCallback, useMemo, useState } from "react";
import { DescargarFactura, VerFactura } from "../components/factura";

export default function useTableColumns(tableRows: any[]) {
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const handleCheckboxChange = useCallback(
    (code: string) => {
      if (selectedRows.includes(code)) {
        setSelectedRows(selectedRows.filter((row) => row !== code));
      } else {
        setSelectedRows([...selectedRows, code]);
      }
    },
    [selectedRows],
  );

  const handleSelectAllChange = useCallback((selectAll: boolean) => {
    if (!selectAll) {
      setSelectedRows([]);
    } else {
      setSelectedRows(
        tableRows
          .filter((row) => row.xml_cpe_link || row.img_cpe_link)
          .map((row) => row.code)
      );
    }
  }, [selectedRows, tableRows]);
  

  const columns: TableColumn[] = useMemo(() => {
    return [
      {
        label: () => {
          const selectableRows = tableRows.filter((row) => row.xml_cpe_link || row.img_cpe_link); 
          return (
            <Checkbox 
              checked={selectedRows.length === selectableRows.length && selectableRows.length > 0}
              onCheckedChange={handleSelectAllChange} 
              disabled={selectableRows.length === 0} 
            />
          );
        },
        key: "select",
        render: (row) => (
          <Checkbox
            checked={selectedRows.includes(row.code)}
            onCheckedChange={() => handleCheckboxChange(row.code)}
            disabled={row.img_cpe_link === "" && row.xml_cpe_link === ""}
          />
        ),
      },
      {
        label: "Fecha de emisión",
        key: "issue_date",
      },
      {
        label: "Serie - Número",
        key: "serie",
        render: ({ serie, number }) => `${serie} - ${number}`,
      },
      {
        label: "Nombre/Razón social del proveedor",
        key: "supplier_business_name",
      },
      {
        label: "Total IGV",
        key: "igv_amount",
        render: ({ igv_amount }) => `s/ ${formatNumber(igv_amount)}`,
      },
      {
        label: "Importe total",
        key: "taxable_amount",
        render: ({ taxable_amount }) => `s/ ${formatNumber(taxable_amount)}`,
      },
      {
        label: "Representación",
        key: "file",
        render: ({ img_cpe_link }) => <VerFactura url={img_cpe_link} />,
      },
      {
        label: "Descargas",
        key: "downloads",
        colSpan: 2,
        render: (row) => (
          <div style={{ display: 'flex', gap: '8px' }}>
            <DescargarFactura url={row.img_cpe_link} type="jpeg" />
            <DescargarFactura url={row.xml_cpe_link} type="xml" />
          </div>
        ),
      },
    ];
  }, [selectedRows, tableRows]);

  return {
    columns,
    selectedRows,
    setSelectedRows,
    handleSelectAllChange,
  };
}
