import { ReactNode } from "react";
import Card from "./CardInfo";

const CardLarge = ({ title, children }: { title: string; children: ReactNode }) => {
    return (
        <div className="bg-white border border-opacity-70 border-neutral-light rounded-2xl h-full py-6 px-4">
            <Card.Title border>
                <p className="text-base font-medium mb-3 f-secondary">{ title }</p>
            </Card.Title>
            <Card.Body>{ children }</Card.Body>
        </div>
    );
};

export default CardLarge;
