import { Button } from "@/components/ui/button";
import iconPadlockCloseModal from "/assets/ic_padlock_shield.svg?url";
import { DialogTitle } from "@radix-ui/react-dialog";
import { WhatsappLink } from "./WhatsappLink";
import useSetBusiness from "@/hooks/use-set-business";
import { useDashboardStore } from "@/store/DashboardStore";

interface LimitedPermissionsProps {
  business: BusinessFilter;
}

export function LimitedPermissions({ business }: LimitedPermissionsProps) {
  const { missing_permissions } = business;
  const { handleRedirectToDashboard } = useSetBusiness();

  const handleClick = () => {
    const filters = useDashboardStore.getState().filters;
    handleRedirectToDashboard(business, filters, "/dashboard");
  };

  return (
    <>
      <div className="text-center">
        <img src={iconPadlockCloseModal} alt="Icono" className="inline-block" />
        <DialogTitle className="text-xl my-2 leading-7 font-medium">
          El "Usuario SOL" tiene permisos limitados
        </DialogTitle>
      </div>
      <div className="text-center space-y-4">
        <p className="text-sm">
          Para recopilar tu información necesitamos el permiso al módulo de:
        </p>
        <ul>
          {missing_permissions.map(({ module_name }) => (
            <li key={module_name}>{module_name}</li>
          ))}
        </ul>
        <p className="text-sm">
          Si necesitas ayuda para gestionar el permiso escríbenos a: <br />
          <WhatsappLink />
        </p>
        {business.exists_periods_processed && (
          <Button type="button" className="mt-4" onClick={handleClick}>
            Ir a ver mi reporte
          </Button>
        )}
      </div>
    </>
  );
}
