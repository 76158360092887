import cardIcon from "/assets/ic_document_denied.svg?url";
interface ProcessingDataCardProps {
	title: string;
	subtitle: string;
}

const ProcessingDataCard = ({title, subtitle }: ProcessingDataCardProps) => {
	return (
	  <div className="bg-white border border-neutral-light border-opacity-70 rounded-2xl h-full flex items-center justify-center">
		  <div className="text-center max-w-[270px]">
			  <div className="mb-3 flex justify-center items-center">
				  <img className="flex-none" src={cardIcon} alt="Data en proceso" />
			  </div>
			  <p className="text-sm font-semibold mb-2">{ title }</p>
			  <p className="text-xs font-medium color--gray">{ subtitle }</p>
		  </div>
	  </div>
	);
}

export default ProcessingDataCard;
