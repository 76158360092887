import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { eventGa4Service } from "../ga4.service.tsx";
import "./SidebarDashboard.css";
import { getInitials, menuListItems } from "../dashboard/helpers.ts";
import { useBusinessStore } from "@/store/BusinessStore.tsx";
import { cn } from "@/lib/utils.ts";

interface BusinessInfo {
  ruc: string;
  businessName: string;
}

function NavbarDesktop({ stateLocation }: { stateLocation: BusinessInfo }) {
  const [_, setActiveIndex] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  const { exists_periods_processed } = useBusinessStore((state) => state.business);

  const [, , currentPath] = location.pathname.split("/");

  const handleItemClick = (index: number, item: MenuItem) => {
    if (!exists_periods_processed) return;

    if (item.tagAnalytics !== "") {
      eventGa4Service({
        action: item.tagAnalytics,
      });
    }
    setActiveIndex(index);
    navigate(item.path, {
      state: {
        page: item.path,
        ...stateLocation,
      },
    });
  };

  return (
    <div className="navbar__links--desktop hidden lg:block" id="step-one">
      {menuListItems.map((item, index) => (
        <div
          key={item.title}
          className={cn(
            "sidebar__item--d rounded-[40px]",
            `${
              exists_periods_processed || item.path === "editar-credenciales"
                ? "cursor-pointer text-neutral-normal hover:bg-surface-100"
                : "cursor-default text-neutral-light"
            }`,
            `${
              (currentPath === item.path || item.subpaths?.includes(location.pathname)) &&
              "bg-surface-200 text-neutral-dark hover:bg-surface-200"
            }`,
          )}
          onClick={() => handleItemClick(index, item)}
        >
          <img
            className="flex-none"
            src={`/assets/ic_${item.icon}${
              !exists_periods_processed && item.path !== "editar-credenciales" ? "--disabled" : ""
            }${
              currentPath === item.path || item.subpaths?.includes(location.pathname)
                ? "--active"
                : ""
            }.svg`}
            alt={item.title}
          />
          <span>{item.title}</span>
        </div>
      ))}
    </div>
  );
}

function SideBarDashboard() {
  const location = useLocation();
  const businessName = location.state?.businessName || location.state?.name;

  return (
    <div
      className="sidebar__container hidden lg:flex lg:flex-col lg:justify-start flex-none overflow-y-auto custom-scroll"
    >
      {businessName && (
        <div className="flex items-center gap-3 mb-6">
          <div className="w-10 h-10 p-4 rounded-[58px] bg-[#A7BFFC] flex justify-center items-center">
            <p className="font-medium text-sm f-secondary text-[#1840A5]">
              {getInitials((businessName))}
            </p>
          </div>
          <p className="font-semibold text-sm">
            <span className="inline-block f-secondary text-sm font-semibold text-[#15161C] -mb-1">
              {businessName}
            </span>
            <span className="inline-block f-secondary text-[10px] font-normal text-[#15161C]">
              RUC {location.state?.ruc}
            </span>
          </p>
        </div>
      )}
      <NavbarDesktop stateLocation={location.state} />
    </div>
  );
}

export default SideBarDashboard;
