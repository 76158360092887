import { businessStatuses } from "@/enums";
import useSetBusiness from "@/hooks/use-set-business";
import { cn } from "@/lib/utils";
import { useDashboardStore } from "@/store/DashboardStore";
import { ChevronRightIcon, CircleXIcon, ClockIcon, TriangleAlertIcon } from "lucide-react";
import { Dispatch, SetStateAction } from "react";

interface BusinessStatusBadgeProps {
  status: BusinessStatus;
}

interface CardProps {
  index: number;
  user: User;
  business: BusinessFilter;
  setBusinessSelected: (business: BusinessFilter) => void;
  setAlertOpen: Dispatch<SetStateAction<boolean>>;
  onClickProcessData?: () => void;
  onClickErrorFetchingData?: () => void;
}

const badgeContentByStatus = {
  [businessStatuses.IN_PROGRESS]: {
    text: "Procesando datos",
    icon: ClockIcon,
    background: "bg-blue-light",
    color: "text-blue-darkest",
  },
  [businessStatuses.MISSING_PERMISSIONS]: {
    text: "Falta de permisos",
    icon: TriangleAlertIcon,
    background: "bg-warning-lightest",
    color: "text-warning-darkest",
  },
  [businessStatuses.INVALID_CREDENTIALS]: {
    text: "Credenciales inválidas",
    icon: CircleXIcon,
    background: "bg-danger-lightest",
    color: "text-danger-darkest",
  },
  [businessStatuses.VALID]: null,
};

function BusinessStatusBadge({ status }: BusinessStatusBadgeProps) {
  const { text, icon: Icon, background, color } = badgeContentByStatus[status] || {};
  return (
    <div
      className={cn("inline-flex gap-1 items-center h-6 rounded-2xl px-2 py-1", background, color)}
    >
      {Icon && <Icon className="w-4 h-4" />}
      <span className="text-xs">{text}</span>
    </div>
  );
}

export const setBackgroundCardIcon = (index: number, userSubscribed: boolean) => {
  if (userSubscribed) {
    return index % 2 === 0 ? "db__card-icon--1" : "db__card-icon--2";
  }

  return "db-card-icon--disabled";
};

export const setBaseClassCardBusiness = (index: number, userSubscribed: boolean) => {
  if (userSubscribed) {
    return index % 2 === 0 ? "db__card--1" : "db__card--2";
  }

  return "db__card--disabled";
};

export const setHoverClass = (business: BusinessFilter, userSubscribed: boolean) => {
  return business.status === businessStatuses.VALID && userSubscribed ? "db__card--hover" : "";
};

export default function BusinessCard({
  index,
  user,
  business,
  setBusinessSelected,
  setAlertOpen,
}: CardProps) {
  const classCssContainerCard =
    "db__card rounded-[24px] px-5 py-4 flex items-center gap-6 cursor-pointer";
  const userSubscribed = user.subscription !== null;
  const { fetchFiltersPeriods } = useDashboardStore((state) => state);
  const { handleRedirectToDashboard } = useSetBusiness();

  const onClickCard = async () => {
    // me garantiza que va retornar periodos en la consulta
    if (business.exists_periods_processed) {
      try {
        await fetchFiltersPeriods(business.uuid);
      } catch (error) {
        console.error(error);
      }
    }

    if (business.status !== "VALID") {
      setBusinessSelected(business);
      setAlertOpen(true);
      return;
    }

    const filters = useDashboardStore.getState().filters; // Obtener el estado actualizado directamente
    handleRedirectToDashboard(business, filters, "/dashboard");
  };

  return (
    <div
      id="business-card"
      className={`${classCssContainerCard} ${setBaseClassCardBusiness(
        index,
        userSubscribed,
      )} ${setHoverClass(business, userSubscribed)}`}
      onClick={onClickCard}
    >
      <div
        className={`flex-none p-4 rounded-[58px] ${setBackgroundCardIcon(index, userSubscribed)}`}
      >
        <img src="/assets/ic_suitcase.svg" alt="Empresa" />
      </div>
      <div className="space-y-2">
        {business.status !== "VALID" && <BusinessStatusBadge status={business.status} />}
        <p className="db__card--title f-secondary">{business.name || business.ruc}</p>
      </div>
      {business.status === businessStatuses.VALID && (
        <div className="ml-auto">
          <ChevronRightIcon className="w-6 h-6" />
        </div>
      )}
    </div>
  );
}
