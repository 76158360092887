import React from 'react';

interface StatusBadgeProps {
    color: string;
    text: string;
}

const StatusBadge: React.FC<StatusBadgeProps> = ({ color, text }) => {
    let badgeColor = '';
    let textColor = '';
    let circleColor = '';
    if (color == 'green') {
        badgeColor = '#BEFF96';
        textColor = '#00555B';
        circleColor = '#0D9E00';
    } else {
        badgeColor = '#FFD78B';
        textColor = '#A13737';
        circleColor = '#A13737';
    }
    return (
        <div
            style={{
                backgroundColor: badgeColor,
                borderRadius: '16px',
                padding: '4px 8px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: textColor,
                fontWeight: 'bold',
                width: 'fit-content',
            }}
        >
            <div
                style={{
                    width: '6px', 
                    height: '6px',
                    backgroundColor: circleColor,
                    borderRadius: '50%',
                    marginRight: '3px',
                }}
            ></div>
            {text}
        </div>
    );
};

export default StatusBadge;