import { cn } from '@/lib/utils';
import React from 'react';

export const FormItem = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    return <div ref={ref} className={cn('my-4', className)} {...props} />;
  },
);
FormItem.displayName = 'FormItem';

export const FormItemMessage = ({
  children,
  error,
}: {
  children: string | React.ReactNode;
  error?: boolean;
}) => (
  <span
    className={cn(
      'flex items-center font-medium tracking-wide text-xs ml-1 mt-1',
      error && 'text-red-500',
    )}
  >
    {children}
  </span>
);
