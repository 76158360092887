import React, { ReactNode, createContext, useState } from "react";
import classNames from "classnames";
import { eventGa4Service } from '../ga4.service.tsx';

// Create a Tab component
// Define the shape of the context
interface TabContextShape {
    currentTab: string;
    changeTab: (tab: string) => void;
}

// Create a context
const TabContext = createContext<TabContextShape>({
    currentTab: "",
    changeTab: () => {},
});

// Define the props for the components
interface TabProps {
    name: string;
    tagAnalytics: string;
    className?: string;
    children: ReactNode;
}

interface TabContentProps {
    name: string;
    className?: string;
    children: ReactNode;
}

interface LayoutProps {
    initialTab: string;
    children: ReactNode;
}

// Create a Tabs component
export const Tabs: React.FC<{ children: ReactNode }> = ({ children }: { children: ReactNode }) => {
    return <div className="tabs flex justify-start border-b-2 p-0 mt-6">{children}</div>;
};

export const Tab: React.FC<TabProps> = ({ name, tagAnalytics, children }) => {
    const { currentTab, changeTab } = React.useContext(TabContext);
    const classIfNotSelected = `p-2 sm:px-4 text-lg font-semibold text-gray-400 border-b-2 border-transparent m-minus-2px`;
    const classIfSelected = `p-2 sm:px-4 text-lg font-semibold text-gray-800 border-b-2 border-primary-blue-2 m-minus-2px transition-all`;
    const handleChangeTab = (name: string) => {
        eventGa4Service({
            action: tagAnalytics,
        });
        changeTab(name);
    }

    return (
        <button onClick={() => handleChangeTab(name)} className={currentTab === name ? classIfSelected : classIfNotSelected}>
            {children}
        </button>
    );
};

// Create a TabContents component
export const TabContents: React.FC<{ children: ReactNode }> = ({ children }) => (
    <div className="tab-contents">{children}</div>
);

// Create a TabContent component
export const TabContent: React.FC<TabContentProps> = ({ name, className = "", children }) => {
    const baseClass = classNames("tab-content", className);

    return (
        <TabContext.Consumer>
            {({ currentTab }) => {
                return currentTab === name ? <div className={baseClass}>{children}</div> : null;
            }}
        </TabContext.Consumer>
    );
};

// Create a Layout component
export const TabLayout: React.FC<LayoutProps> = ({ initialTab, children }) => {
    const [currentTab, setCurrentTab] = useState(initialTab);

    return <TabContext.Provider value={{ currentTab, changeTab: setCurrentTab }}>{children}</TabContext.Provider>;
};
