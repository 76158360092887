import { DialogTitle } from "@/components/ui/dialog";
import { WhatsappLink } from "./WhatsappLink";
import iconClockModal from "/assets/ic_clock_close_d.svg?url";

export function DataProcessing() {
  return (
    <>
      <div className="text-center">
        <img src={iconClockModal} alt="Icono" className="inline-block" />
        <DialogTitle className="text-xl my-2 leading-7 font-medium">
          Estamos procesando tus datos
        </DialogTitle>
      </div>
      <div className="text-center">
        <p className="text-sm">
          La consolidación y análisis de los comprobantes de compras y ventas de la plataforma
          SUNAT, puede demorar hasta 2 días.
        </p>
        <p className="text-sm">Si tienes alguna duda o consulta, escríbenos a:</p>
        <WhatsappLink />
      </div>
    </>
  );
}
