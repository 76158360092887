import { eventGa4Service } from "@/ga4.service.tsx";
import { CONTAPRO_ANALYTICS } from "@/shared/analytics.tsx";
import { useBusinessStore } from "@/store/BusinessStore.tsx";
import { useDashboardStore } from "@/store/DashboardStore";
import { useNavigate } from "react-router-dom";

export default function useSetBusiness() {
  const navigate = useNavigate();
  const { setBusiness } = useBusinessStore((state) => state);
  const { setValuesFilterSelected } = useDashboardStore((state) => state);

  const handleRedirectToDashboard = (
    business: Business,
    filters: Filters,
    goToUrlDashboard: string,
  ) => {
    if (!business) {
      return;
    }

    setBusiness({
      uuid: business.uuid,
      name: business.name,
      ruc: business.ruc,
      status: business.status,
      exists_periods_processed: true, // business.exists_periods_processed,
      privacy: false,
      registered: false,
      terms: false,
    });

    const initialSelectedTaxPeriod =
      filters.tax_periods && filters.tax_periods.length > 0 ? filters.tax_periods[0] : null;

    setValuesFilterSelected({
      ruc: business.ruc,
      uuid: business.uuid,
      tax_period: initialSelectedTaxPeriod,
    });

    eventGa4Service({
      action: CONTAPRO_ANALYTICS.HOME.CARD_BUSINESS.ACTION,
    });

    navigate(goToUrlDashboard, {
      state: {
        ruc: business.ruc,
        businessName: business.name,
        uuid: business.uuid,
      },
    });
  };

  return { handleRedirectToDashboard };
}
