import { ModalState } from '@/shared/Modal';
import { useCallback, useState, useEffect } from 'react';

const useLoadImage = (
    urlReport: string,
    modalState: string,
    addLoadingTag: (tag: string) => void,
    removeLoadingTag: (tag: string) => void,
  ) => {
    const [isImageLoaded, setIsImageLoaded] = useState(false);
  
    const loadImage = useCallback(() => {
      const img = new Image();
      img.src = urlReport;
  
      const imageLoad = () => {
        setIsImageLoaded(true);
        removeLoadingTag('loading');
      };
  
      img.onload = imageLoad;
      if (img.complete) imageLoad();
      else addLoadingTag('loading');
    }, [urlReport, addLoadingTag, removeLoadingTag]);
  
    useEffect(() => {
      if (modalState === ModalState.OPEN) {
        setIsImageLoaded(false);
        loadImage();
        removeLoadingTag('loading');

      } else {
        removeLoadingTag('loading');
      }
    }, [modalState, loadImage, removeLoadingTag, ModalState]);
  
    return { isImageLoaded, loadImage, removeLoadingTag };
  };
  

export default useLoadImage;
