import { useApplicationStore } from "./store/ApplicationStore";
import "./Loader.css";
import spinner from "/assets/spinner.svg?url";

export default function Loader() {
    const loadingTags = useApplicationStore((state) => state.loadingTags);
    return (
        loadingTags.length > 0 && (
            <div className="loader-container">
                <img className="spinner" src={spinner} alt="spinner" />
            </div>
        )
    );
}
