import Loader from "../Loader";
import RegisterAccountantForm from "./RegisterAccountantForm";
import "./RegisterAccountant.css";
import logoContapro from "/assets/logo-contapro-credicorp.png?url";
import logoShield from "/assets/ic_shield_insurance.svg?url";
import Credicorp from "/assets/Credicorp-Logo-original.png?url";
import CardImg1 from "/assets/register_accountant_1.png?url";
import CardImg2 from "/assets/register_accountant_2.png?url";
import CardImg3 from "/assets/register_accountant_3.png?url";

import Card from '../shared/CardInfo.tsx';
import CommentCarousel from "@/dashboard/CommentCarousel.tsx";


const URL_BASE_VIDEO = import.meta.env.VITE_URL_BASE_ASSETS;
const URL_VIDEO_REGISTRO_CONTAPRO = `${URL_BASE_VIDEO}/video_registro_contapro.mp4`;

function RegisterAccountant() {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <main className="min-h-screen w-full">
            <Loader/>
            <div className="xl:min-h-screen xl:flex bg-white xl:bg-[#E0E9FF]">
                <div className="w-full bg-white xl:w-7/12 flex flex-col">
                    <div className="xl:max-w-[488px] mx-auto w-full py-5 px-5 mb-4 xl:px-0 border-b xl:border-none">
                        <img src={ logoContapro } alt="Contapro"/>
                    </div>
                    <RegisterAccountantForm/>
                </div>

                <div className="hidden xl:flex items-center justify-center xl:w-5/12">
                    <div className="w-100 mx-8">
                        <video 
                            src={URL_VIDEO_REGISTRO_CONTAPRO} 
                            controls 
                            className="w-full rounded-[25px] object-cover" 
                            autoPlay
                        >
                        </video>
                        <div className="flex items-center justify-center space-x-2 my-8">
                            <img src={logoShield} alt="Contapro" className="h-6 w-4" />
                            <p className="text-sm my-0">Con el respaldo de</p>
                            <img src={Credicorp} alt="logo Credicorp" className="h-4 w-[100px]" />
                        </div>
                    </div>
                </div>
            </div>
            <hr className="mt-4 xl:mt-0"/>
            <div className="container mx-auto px-4 mt-6 mb-4 lg:my-16 max-w-[1062px]">
                <h2 className="mb-4 font-bold text-2xl">Deja de perder tiempo en la <span
                    className="font-bold text-[#1B68FF]">declaración de tus clientes</span></h2>

                <Card className="register-accountant-card__1 bg-[#E0F3FF] border-none mb-4 rounded-3xl">
                    <div className="md:flex gap-4">
                        <div className="mb-2 md:mb-0">
                            <p className="font-semibold text-lg lg:text-[20px]">Indicadores importantes para tus
                                empresas</p>
                            <p className="text-base f-secondary lg:text-lg">Cálculos de IGV, comprobantes, percepciones,
                                retenciones, y más en un solo reporte
                                por
                                periodo.</p>
                        </div>
                        <div className="flex-none register-accountant-card__1--img">
                            <img className="w-full inline-block h-auto" src={ CardImg1 } alt="Indicadores imagen"/>
                        </div>
                    </div>
                </Card>

                <div className="lg:flex gap-4">
                    <Card className="border-none w-full lg:w-1/2 bg-[#E0F3FF] mb-4 lg-mb-0 rounded-3xl">
                        <div className="register-accountant-card__2--img text-center mx-auto mb-2">
                            <img className="inline-block w-full h-auto" src={ CardImg2 } alt="Facturas imagen"/>
                        </div>
                        <div>
                            <p className="font-semibold text-lg lg:text-[20px]">Facturas con ítems</p>
                            <p className="text-base f-secondary lg:text-lg">Extraemos las facturas de SIRE y SUNAT
                                completas.</p>
                        </div>
                    </Card>
                    <Card className="border-none w-full lg:w-1/2 bg-[#E0F3FF] rounded-3xl">
                        <div className="register-accountant-card__2--img mx-auto mb-2">
                            <img className="w-full h-auto inline-block" src={ CardImg3 }
                                 alt="Analisis de facturas imagen"/></div>
                        <div>
                            <p className="font-semibold text-lg lg:text-[20px]">Análisis y observaciones</p>
                            <p className="text-base f-secondary lg:text-lg">Identificamos gastos ajenos a la empresa por
                                ti.</p>
                        </div>
                    </Card>
                </div>
            </div>

            <CommentCarousel />
            
            <div className="bg-[#15161C] px-4">
                <div className="container mx-auto max-w-[1062px] py-4 lg:py-16 text-center">
                    <h2 className="font-bold text-xl lg:text-[32px] text-white max-w-[846px] mx-auto mb-4">Olvídate de
                        las tareas manuales de la declaración con Contapro</h2>
                    <p className="font-normal text-base lg:text-lg text-white">Elige el plan que se adapte mejor a ti, y
                        regístrate.</p>

                    <button onClick={ scrollToTop } type="button"
                            className="rounded-[32px] py-2 px-6 mt-4 bg-white text-[#15161C] f-secondary font-semibold text-base">Regístrate
                    </button>
                    <hr className="hidden mt-[50px] lg:block bg-[#5D6075]"/>

                </div>
            </div>


        </main>
    );
}

export default RegisterAccountant;
