import { TooltipObservation } from "./TooltipObservation";
import tooltipIcon from "/assets/ic_question_circle.svg?url";

const ObservationHeaderCell = () => {
  return (
    <div className="flex min-w-48 ">
      <p className="text-neutral-darkest font-bold">Observación</p>
      <TooltipObservation
        text={
          "Al rechazar una factura estás de acuerdo con nuestro análisis y rechazas la factura. \n Al validar una factura, justificas el comprobante y lo transferimos a los gastos deducibles de la empresa."
        }
      >
        <img src={tooltipIcon} alt="Somos Contapro" />
      </TooltipObservation>
    </div>
  );
};
export default ObservationHeaderCell;
