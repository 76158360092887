import Modal from "@/shared/Modal";
import { ChevronLeftIcon, ChevronRightIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { ObservedValidatedModalMasiveProps } from "../models/observedValidatedModalMasive";
import ValidatedAllSelectInvoiceForm from "./ValidatedAllSelectInvoiceForm";

const ValidateAllSelectInvoiceModal = ({
  modalState,
  setModalState,
  closeModalCallback,
  cpes,
}: ObservedValidatedModalMasiveProps) => {
  const [index, setIndex] = useState<number>(0);
  const prevBill = () => {
    setIndex(index - 1);
  };

  useEffect(() => {
    setIndex(0);
  }, [closeModalCallback]);

  return (
    <Modal
      modalState={modalState}
      typeModal="editMasiveReport"
      showAlertIcon={false}
      showIconClose={false}
      className="w-[90%] md:w-[60%] max-w-[600px] p-0 rounded-[16px]"
      onClose={closeModalCallback}
    >
      <div className="w-full">
        <div className="flex justify-between items-center bg-[#EDF2FA] rounded-t-[16px] p-4">
          {index > 0 ? (
            <ChevronLeftIcon
              className="cursor-pointer text-neutral-dark"
              size={32}
              onClick={() => prevBill()}
            ></ChevronLeftIcon>
          ) : (
            <>
              <div className="ml-4"></div>
            </>
          )}
          <div className="flex items-center gap-3">
            <h2 className="text-[16px] leading-6 text-neutral-dark font-semibold">
              Validar factura #{cpes[index]?.number}
            </h2>
            <span className="text-[8px] rounded-2xl bg-white py-1 px-2">
              {index + 1} de {cpes.length}
            </span>
          </div>
          {index < cpes.length - 1 ? (
            <ChevronRightIcon
              className="cursor-pointer text-neutral-dark"
              size={32}
              onClick={() => setIndex(index + 1)}
            ></ChevronRightIcon>
          ) : (
            <>
              <div className="mr-4"></div>
            </>
          )}
        </div>
        {cpes.length > 0 && (
          <ValidatedAllSelectInvoiceForm cpes={cpes} setModalState={setModalState} index={index} />
        )}
      </div>
    </Modal>
  );
};

export default ValidateAllSelectInvoiceModal;
