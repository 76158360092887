import { TableColumn } from "@/components/ui/full-table";
import { formatDate, formatNumber } from "@/dashboard/helpers";
import { ViewVoucherModal } from "../components/modal";

interface DetractionsTableColumn extends TableColumn {
    status?: string
}

const columns: DetractionsTableColumn[] = [
  {
    label: "Fecha de emisión",
    key: "issuer_date",
    render: ({ issuer_date }) => `${formatDate(issuer_date)}`
  },
  {
    label: "Serie - Número",
    key: "serie_number",
  },
  {
    label: "Nombre/Razón social del proveedor",
    key: "supplier_business_name",
  },
  {
    label: "Total de factura",
    key: "total_amount",
    render: ({ total_amount }) => `s/ ${formatNumber(total_amount)}`,
  },
  {
    label: "Valor de detracción",
    key: "detraction_value",
    render: ({ detraction_value }) => `s/ ${formatNumber(detraction_value) || '-'}`,
    status: "S",
  },
  {
    label: "Fecha de depósito",
    key: "payment_datetime",
    status: "S",
  },
  {
    label: "N° de constancia",
    key: "certificate_number",
    status: "S",
  },
  {
    label: "Tipo de operación",
    key: "operation_name",
    status: "S",
  },
  {
    label: "Representación",
    key: "",
    status: "S",
    render: (row) => <ViewVoucherModal data={row} />
  }
];

 export default function useTableColumns(status: string) {
   return columns.filter(column => {
       return (!column.status || column.status === status)
   })
 }