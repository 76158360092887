import TagManager from "react-gtm-module";
import { useAuthStore } from "./store/AuthStore";

interface Ga4ServiceProps {
    action: string;
}

export const eventGa4Service = ({ action }: Ga4ServiceProps) => {
    const { user } = useAuthStore.getState();
    const userId = user?.uuid || '';
    const parts = action.split('_');
    const window = parts[1];
    TagManager.dataLayer({
        dataLayer: {
            event: "virtualEvent",
            accion: action,
            pantalla: window,
            user_id: userId,
        },
    });
};