import NewBusinessForm from "./NewBusinessForm";
import { BusinessCreationStatus, useBusinessStore } from "../store/BusinessStore";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import "../App.css";
import "./BusinessAlreadyRegistered.css";
import { useApplicationStore } from "../store/ApplicationStore";
import NavBar from '../shared/NavBar.tsx';
import Loader from '../Loader.tsx';

function BusinessAlreadyRegistered() {
    const business = useBusinessStore((state) => state.business);
    return (
        business.registered && (
            <section id="already-registered-alert" className="p-1">
                <div className="panel text-center p-8 max-w-md">
                    <div className="flex justify-center">
                        <ExclamationCircleIcon className="h-12 w-12 bg-transparent text-primary-blue" />
                    </div>
                    <h2 className="m-6 text-2xl font-bold text-primary-blue">La Pyme ya se encuentra registrada</h2>
                    <p className="text-lg font-semibold text-secondary-blue">
                        Ponte en contacto con nosotros para obtener más información sobre tu cuenta
                    </p>
                    <a
                        href="https://www.contapymebcp.com/"
                        className="mt-8 mb-4 inline-block w-full rounded-full bg-primary-yellow py-4 text-sm font-bold text-secondary-blue shadow-xl hover:bg-secondary-blue hover:text-primary-yellow">
                        Click aquí
                    </a>
                </div>
            </section>
        )
    );
}

function NewBusiness() {
    const showAccountantProfileSOLForm = useApplicationStore((state) => state.showAccountantProfileSOLForm);
    const setBusinessCreatedSuccessFully = useBusinessStore((state) => state.setBusinessCreatedSuccessFully);

    setBusinessCreatedSuccessFully(BusinessCreationStatus.NOT_CREATED);

    return (
        <>
            <Loader/>
            <NavBar customCssStyle="border-b"/>
            <div className="px-6 py-8">

                {<BusinessAlreadyRegistered />}
                {showAccountantProfileSOLForm && <NewBusinessForm />}
            </div>
        </>
    );
}

export default NewBusiness;
