import { buttonVariants } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { Link } from "react-router-dom";

export default function FriendlyNotFound() {
  return (
    <div id="error-page" className="h-screen flex flex-col items-center justify-center text-center gap-4 px-2">
      <h1 className="text-neutral-400 font-black text-6xl">404</h1>
      <h2 className="text-2xl text-primary-black font-bold">¡Oops! Algo salió mal</h2>
      <p className="text-lg text-neutral-normal">La página solicitada no está disponible</p>
      <Link to="/" className={cn(buttonVariants())}>
        Volver al inicio
      </Link>
    </div>
  );
}
