import { ReactNode } from "react";
import { Tooltip, TooltipArrow, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";

export interface ButtonDownloadWithTooltipProps {
    children: ReactNode
    description: string
    side?: "top" | "right" | "bottom" | "left"
    className? : string
}

export function ButtonWithTooltip({children, description, side = "top", className}: ButtonDownloadWithTooltipProps) {
    return (
        <TooltipProvider delayDuration={100}>
          <Tooltip>
            <TooltipTrigger asChild>
              {children}
            </TooltipTrigger>
            <TooltipContent className={className} side={side}>
              <p className="text-center">{description}</p>
              <TooltipArrow  className="fill-current text-white" />
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )
}
