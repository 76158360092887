import { useNavigate } from "react-router-dom";
import emptyStateBusinessImg from "/assets/ic_business-list-close.svg?url";
import { eventGa4Service } from "@/ga4.service.tsx";
import { CONTAPRO_ANALYTICS } from "@/shared/analytics.tsx";
import { Button } from "@/components/ui/button.tsx";

export default function EmptyBusiness() {
  const navigate = useNavigate();

  const onClickRegisterBusiness = () => {
    eventGa4Service({
      action: CONTAPRO_ANALYTICS.HOME.MENU_BUSINESS_REGISTER.ACTION,
    });
    navigate("/registrar-empresa", {
      state: { newUser: true },
    });
  };

  return (
    <div className="w-full h-full flex items-center justify-center">
      <div className="flex-none text-center">
        <img className="inline-block mb-3" src={emptyStateBusinessImg} alt="No hay empresas" />
        <h2 className="font-bold text-2xl md:text-[28px] mb-3">No tienes empresas registradas</h2>
        <p className="font-sm md:font-base mb-3">Registra tu primera empresa</p>
        <Button onClick={onClickRegisterBusiness}>Registrar empresa</Button>
      </div>
    </div>
  );
}
