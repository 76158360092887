import { ReactNode } from "react";
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from "./table";

export interface TableColumn {
  key: string;
  label: string | (() => ReactNode);
  render?: (row: any) => ReactNode
}

interface TableProps {
  data: any[];
  columns: TableColumn[];
}

export default function FullTable({ data, columns }: TableProps) {
  return (
    <Table>
      <TableHeader className="sticky top-0 z-5">
        <TableRow>
          {columns.map((column) => (
            <TableHead key={column.key}>
              {typeof column.label === 'function' ? column.label() : column.label}
            </TableHead>
          ))}
        </TableRow>
      </TableHeader>
      {data.length ? (
        <TableBody>
          {data.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {columns.map((column) => (
                <TableCell key={`${rowIndex}-${column.key}`}>
                  {column.render ? column.render(row) : row[column.key]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      ) : (
        <TableCaption>
          <p className="text-lg text-neutral-500 my-10">No se encontraron resultados</p>
        </TableCaption>
      )}
    </Table>
  );
};
