import { useState, useEffect, useRef } from 'react';
import './UploadFilesAT.css'

const urlFormAT = import.meta.env.VITE_URL_FORM_AT_EMBED

const UploadFilesAT = () => {
  const [isIframeLoaded, setIsIframeLoaded] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [messageKey, setMessageKey] = useState(0);

  const iframeRef = useRef(null);

  const messages = [
    'Cargando el formulario...',
    'Cargando los registros...',
    'Cargando las imágenes...',
    'El formulario está casi listo...'
  ];


  useEffect(() => {
    let messageIndex = 0;
    
    const interval = setInterval(() => {
      setLoadingMessage(messages[messageIndex]);
      setMessageKey(prevKey => prevKey + 1);
      messageIndex++;
      
      if (messageIndex === messages.length) {
        clearInterval(interval);
      }
    }, 2200); 
    
    return () => clearInterval(interval);
  }, []);

  const handleIframeLoad = () => {
    setIsIframeLoaded(true);
  };

  return (
    <main>
      <div className="relative min-h-screen bg-white w-full">
        {!isIframeLoaded && (
          <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-white">
            <p key={messageKey} className="message">{loadingMessage}</p>
          </div>
        )}

        <iframe 
          ref={iframeRef} 
          className="airtable-embed" 
          src={urlFormAT}
          width="100%" 
          height="533" 
          style={{background: 'transparent', border: 'none'}}
          onLoad={handleIframeLoad}
        />
      </div>
    </main>
  );
};

export default UploadFilesAT;