import { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { eventGa4Service } from '../ga4.service.tsx';
import { CONTAPRO_ANALYTICS } from '../shared/analytics.tsx';

const URL_BASE_VIDEO = import.meta.env.VITE_URL_BASE_ASSETS;
const URL_VIDEO_ONBOARDING = `${URL_BASE_VIDEO}/observaciones-contapro.mp4`;
const URL_VIDEO_CONTAPRO_INTRO = `${URL_BASE_VIDEO}/intro-contapro.mp4`;

type ModalType = 'onboarding' | 'registerSuccess';

interface ModalProps {
	isShowModal: boolean;
	onCloseModal: () => void;
	videoUrl: string;
	buttonText: string;
	buttonAction: () => void;
	handlePlayVideoIntro?: () => void;
	title: string;
	description: string;
}

const labelsModal = {
	onboarding: {
		title: "Ahorra tiempo validando comprobantes",
		buttonText: "Ver observaciones del periodo",
		description:
			"Contapro analiza tus comprobantes de compra y realiza la discriminación por ti, con la opción de poder decidir si mantienes un comprobante en tu registro.",
	},
	registerSuccess: {
		title: "¡Te has registrado! Mira todo lo que Contapro puede hacer por ti",
		buttonText: "Registrar empresas",
		description:
			"Recopilamos los comprobantes de SIRE y SUNAT para que te olvides de pedírselos a tus clientes, y realizamos el análisis respectivo.",
	}
}

const Modal = ({
	               isShowModal,
	               onCloseModal,
	               videoUrl,
	               buttonText,
	               buttonAction,
	               title,
	               description,
	               handlePlayVideoIntro}: ModalProps) => {
	const videoRef = useRef<HTMLVideoElement>(null);

	const handleCloseModal = () => {
		videoRef.current?.pause();
		onCloseModal();
	}

	const handleButtonAction = () => {
		videoRef.current?.pause();
		buttonAction();
	}

	return (
		<div
			className={`fixed inset-0 flex items-center justify-center ${
				isShowModal ? "flex" : "hidden"
			}`}
		>
			<div className="fixed inset-0 bg-black opacity-50"></div>
			<div className="relative z-50 bg-white p-6 rounded-md shadow-md min-w-64 min-h-80 w-11/12 sm:w-4/6 max-w-xl flex items-center justify-center">
				<button
					onClick={handleCloseModal}
					className="absolute top-4 right-4 text-gray-700 hover:text-gray-900"
				>
					<svg
						className="w-3 h-3"
						aria-hidden="true"
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 14 14"
					>
						<path
							stroke="currentColor"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
							d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
						/>
					</svg>
				</button>

				<div className="block text-center my-4 max-w-[519px]">
					<h2 className="font-bold text-2xl mb-2">{title}</h2>
					<p className="text-sm">{description}</p>
					<div className="text-center my-5">
						<div className="max-w-[374px] mx-auto">
							<video
								ref={videoRef}
								onPlay={handlePlayVideoIntro}
								className="w-full h-full rounded-[16px]"
								controls
								width="600"
							>
								<source src={videoUrl} type="video/mp4" />
								Tu navegador no soporta la reproducción de video.
							</video>
						</div>
					</div>
					<button
						onClick={handleButtonAction}
						className="flex-none rounded-[20px] w-full mb-3 sm:mb-0 sm:w-[266px] px-3 py-2 bg-stone-950 text-white"
					>
						{buttonText}
					</button>
				</div>
			</div>
		</div>
	);
};

interface ModalVideoTutorialProps { 
	modalType: ModalType; 
	showModal: boolean; 
	onClose?: () => void 
}

const ModalVideoTutorial = ({ modalType, showModal, onClose }: ModalVideoTutorialProps) => {
	const location = useLocation();
	const navigate = useNavigate();
	const [isShowModal, setIsShowModal] = useState(showModal);
	const { ruc, businessName } = location.state || {};

	const handleCloseModalRegisterSuccess = () => {
		localStorage.setItem("isFirstLogin", "false");
		setIsShowModal(false);
	};

	const handleOnCloseModalOnboarding = () => {
		localStorage.setItem("completedOnboardingVideo", "true");
		setIsShowModal(false);
		onClose && onClose();
	}

	const handleCloseModal = () => {
		if (modalType === "onboarding") {
			handleOnCloseModalOnboarding();
		}

		if (modalType === "registerSuccess") {
			handleCloseModalRegisterSuccess();
		}
	}

	const setUrlModal = (modalType: string): string => {
		switch (modalType) {
			case "onboarding":
				return URL_VIDEO_ONBOARDING;
			case "registerSuccess":
				return URL_VIDEO_CONTAPRO_INTRO;
			default:
				return "";
		}
	};

	const handleClickModalOnboarding = () => {
		localStorage.setItem("completedOnboardingVideo", "false");

		const currentPath = location.pathname;
		const newUrl = `${currentPath}/observaciones`;
		navigate(newUrl, {
			state: {
				page: 'factura-observada',
				ruc,
				businessName,
			},
		});
	}

	const handleCliclModalRegisterSuccess = () => {
		eventGa4Service({
			action: CONTAPRO_ANALYTICS.HOME.MODAL_ONBOARDING_REGISTER.BUTTON_REGISTER.ACTION,
		});
		localStorage.setItem("isFirstLogin", "false");
		navigate('/registrar-empresa');
	}

	const handleClickButtonModal = (modalType: string) => {
		if (modalType === 'onboarding') {
			handleClickModalOnboarding();
		}

		if (modalType === 'registerSuccess') {
			handleCliclModalRegisterSuccess();
		}

		setIsShowModal(false);
	}

	const handlePlayVideoOnoboardingRegister = () => {
		eventGa4Service({
			action: CONTAPRO_ANALYTICS.HOME.MODAL_ONBOARDING_REGISTER.PLAY_VIDEO.ACTION,
		});
	}

	const handleClickPlayVideo = (modalType: string) => {
		if (modalType === 'onboarding') {
			console.log('play video onboarding dashboard')
		}

		if (modalType === 'registerSuccess') {
			handlePlayVideoOnoboardingRegister();
		}
	}

	return (
		<Modal
			isShowModal={isShowModal}
			onCloseModal={handleCloseModal}
			videoUrl={setUrlModal(modalType)}
			buttonText={labelsModal[modalType].buttonText}
			buttonAction={() => handleClickButtonModal(modalType)}
			title={labelsModal[modalType].title}
			description={labelsModal[modalType].description}
			handlePlayVideoIntro={() => handleClickPlayVideo(modalType)}
		/>
	);
};

export default ModalVideoTutorial;
