import { cn } from "@/lib/utils";
import React, { ReactElement } from "react";

interface TitleProps {
    title?: string;
    children?: React.ReactNode;
    border?: boolean;
}

interface StaticCardProps {
    Title?: React.FC<TitleProps>;
    Body: React.FC<{ children: React.ReactNode }>;
    Footer?: React.FC<{ children: React.ReactNode }>;
}

interface CardProps {
    className?: string;
    text?: string;
    children: React.ReactNode;
}

const Title = ({ title, children, border = false }: TitleProps) => {
    return (
        <>
            {title || children}
            {border && <hr />}
        </>
    );
};

const Body = ({ children }: { children: React.ReactNode }) => {
    return <div>{children}</div>;
};

const Footer = ({ children }: { children: React.ReactNode }) => {
    return <div>{children}</div>;
};

const Card = ({ className = "", text, children }: CardProps): ReactElement<StaticCardProps> => {
    return (
        <div className={cn(`bg-white border border-opacity-70 border-neutral-light rounded-2xl py-6 px-4`, className)}>
            {text && <div className="">{text}</div>}
            {children}
        </div>
    );
};

Card.Title = Title;
Card.Body = Body;
Card.Footer = Footer;

export default Card;
