export default function LoadingText() {
    return (
        <div className="wrapper">
            <div className="loading-text">
                <h1>
                    Cargando empresas...
                </h1>
            </div>
        </div>
    );
}
