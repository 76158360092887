import { TooltipRenderProps } from "react-joyride";
import { cn } from "../lib/utils";
import { XMarkIcon } from "@heroicons/react/24/solid";

// TODO: Create a global Button component
const Button = ({ className, ...props }: React.BaseHTMLAttributes<HTMLButtonElement>) => {
    return (
        <button
            className={cn(
                `text-white text-sm border font-semibold rounded-2xl bg-black px-3 py-1 outline-none`,
                className,
            )}
            {...props}
        />
    );
};

const TourTooltip = ({
    index,
    step,
    backProps,
    closeProps,
    primaryProps,
    isLastStep,
    tooltipProps,
    size,
}: TooltipRenderProps) => (
    <div className="max-w-80 bg-neutral-900 rounded-md relative p-5" {...tooltipProps}>
        {step.title && <h3 className="font-bold text-white mb-2">{step.title}</h3>}
        <p className="text-sm text-white opacity-80">{step.content}</p>
        <div className="flex items-center justify-between mt-5">
            {<p className="text-white text-sm opacity-75">
                {index + 1} de {size}
            </p>}
            <div className="flex gap-3">
                {index > 0 && (
                    <Button id="back" {...backProps}>
                        Atrás
                    </Button>
                )}
                <Button id="next" {...primaryProps} className="bg-white text-black">
                    {isLastStep ? "Finalizar" : "Siguiente"}
                </Button>
            </div>
        </div>
        <span id="close" {...closeProps} className="absolute top-1 right-1 cursor-pointer">
            <XMarkIcon className="h-5 w-5 text-white opacity-70" />
        </span>
    </div>
);

export default TourTooltip;
