const PendingObservedItems = ({
  cpes,
  onAprobeReport,
}: {
  cpes: Cpes;
  onAprobeReport: () => void;
}) => {
  return (
    <>
      <h2 className="text-xl leading-7 font-medium mb-4">Items Comprobante #{cpes?.number}</h2>
      <div className="p-4">
        <ul className="list-disc">
          {cpes?.items?.map((item, index) => <li key={"item" + index}>{item.description}</li>)}
        </ul>
      </div>

      {cpes?.observation && (
        <>
          <hr />
          <div className="mt-4">
            <p className="text-sm leading-5 font-semibold">Observación</p>
            <p className="text-sm leading-5 font-normal mt-2">{cpes?.observation}</p>
          </div>
        </>
      )}

      <div className="w-full text-right mt-4">
        <button
          className="bg-primary-black rounded-[20px] px-3 py-2 font-medium font-xs text-white"
          onClick={() => onAprobeReport()}
        >
          Validar factura
        </button>
      </div>
    </>
  );
};

export default PendingObservedItems;
