export const CONTAPRO_ANALYTICS = {
	AUTH: {
		LOGIN_PAGE: {
			BUTTON_LOGIN: {
				ACTION: 'clic_inicioSesion1_botonLogin'
			},
			BUTTON_NEW_ACCOUNT: {
				ACTION: 'clic_inicioSesion1_botonRegistrate'
			},
			BUTTON_FORGOT_PSW: {
				ACTION: 'clic_inicioSesion1_botonOlvidoPass'
			},
			LOGIN_ERROR: {
				ACTION: 'clic_inicioSesion1_codigoErrorWrongPass'
			},
		},
		REGISTER_USER_PAGE: {
			BUTTON_REGISTER: {
				ACTION: 'clic_registro_botonCrearCuenta'
			},
			BUTTON_LOGIN: {
				ACTION: 'clic_inicioSesion1_botonRegistrate',
			},
			BUTTON_REGISTER_COMPANY: {
				ACTION: 'clic_RegistroEmpresas_botonSiguienteEmpresa'
			},
			BUTTON_REGISTER_LATER: {
				ACTION: 'clic_RegistroEmpresas_botonRegistrarDespues'
			},
			BUTTON_REGISTER_FINISHED: {
				ACTION: 'clic_RegistroEmpresas_botonFinalizarRegistroEmpresas'
			},
		}
	},
	HOME: {
		LOGIN_SUCCESSFUL: {
			ACTION: 'clic_home1_inicioSesionCorrecto'
		},
		MENU_HOME: {
			ACTION: 'clic_home1_botonInicio'
		},
		MENU_BUSINESS_REGISTER: {
			ACTION: 'clic_home1_botonRegistroEmpresa'
		},
		MENU_LOGOUT: {
			ACTION: 'clic_home1_botonCerrarSesion'
		},
		CARD_BUSINESS: {
			ACTION: 'clic_home1_tabEmpresa'
		},
		MODAL_ONBOARDING_REGISTER: {
			BUTTON_REGISTER: {
				ACTION: 'clic_modalOnboarding_botonRegistrarEmpresas'
			},
			PLAY_VIDEO: {
				ACTION: 'clic_modalOnboarding_playVideo'
			}
		},
		ALERT_FREE_PLAN: {
			ACTION: 'clic_PruebaGratis_botonMasInformacion'
		},
		ALERT_EXPIRED_PLAN: {
			ACTION: 'clic_home_botonElegirPlan'
		},
	},
	PLANS: {
		SELF_REGISTRATION: {
			MONTHLY_1: {
				ACTION: 'clic_planesAutoregistro_botonMensualPlan1'
			},
			MONTHLY_2: {
				ACTION: 'clic_planesAutoregistro_botonMensualPlan2'
			},
			MONTHLY_3: {
				ACTION: 'clic_planesAutoregistro_botonMensualPlan3'
			},
			MONTHLY_4: {
				ACTION: 'clic_planesAutoregistro_botonMensualPlan4'
			},
			ANNUAL_1: {
				ACTION: 'clic_planesAutoregistro_botonAnualPlan1'
			},
			ANNUAL_2: {
				ACTION: 'clic_planesAutoregistro_botonAnualPlan2'
			},
			ANNUAL_3: {
				ACTION: 'clic_planesAutoregistro_botonAnualPlan3'
			},
			ANNUAL_4: {
				ACTION: 'clic_planesAutoregistro_botonAnualPlan4'
			},
		},
		FREE: {
			MONTHLY_1: {
				ACTION: 'clic_planesPruebaGratis_botonMensualPlan1'
			},
			MONTHLY_2: {
				ACTION: 'clic_planesPruebaGratis_botonMensualPlan2'
			},
			MONTHLY_3: {
				ACTION: 'clic_planesPruebaGratis_botonMensualPlan3'
			},
			MONTHLY_4: {
				ACTION: 'clic_planesPruebaGratis_botonMensualPlan4'
			},
			ANNUAL_1: {
				ACTION: 'clic_planesPruebaGratis_botonAnualPlan1'
			},
			ANNUAL_2: {
				ACTION: 'clic_planesPruebaGratis_botonAnualPlan2'
			},
			ANNUAL_3: {
				ACTION: 'clic_planesPruebaGratis_botonAnualPlan3'
			},
			ANNUAL_4: {
				ACTION: 'clic_planesPruebaGratis_botonAnualPlan4'
			},
		},
	},
	BUSINESS_REGISTER_FORM: {
		BUTTON_REGISTER: {
			ACTION: 'clic_registroEmpresa_botonEnviar'
		},
	},
	DASHBOARD: {
		TAB_SALES: {
			ACTION: 'clic_dashboard1_tabVentas'
		},
		TAB_PURCHASES: {
			ACTION: 'clic_dashboard1_tabCompras'
		},
		DOWNLOAD_REPORT: {
			ACTION: 'clic_dashboard1_botonDescargar1'
		},
		MODAL_DOWNLOAD_REPORT: {
			BUTTON_DOWNLOAD: {
				ACTION: 'clic_modalDescarga_botonDescargar2'
			},
			OPTION_RCE: {
				ACTION: 'drag_modalDescarga_desplegableRCE'
			},
			OPTION_RVIE: {
				ACTION: 'drag_modalDescarga_desplegableRVIE'
			},
			OPTION_SALES_CONTAPRO: {
				ACTION: 'drag_modalDescarga_desplegableVentasContapro'
			},
			OPTION_PURCHASES_CONTAPRO: {
				ACTION: 'drag_modalDescarga_desplegableComprasContapro'
			}
		}
	}
};
