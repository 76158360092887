export default function isValidRUC(ruc: string): boolean {
  const rucDigits = ruc.split('').map(Number);

  const firstTwoDigits = parseInt(ruc.substring(0, 2), 10);
  if (![10, 15, 16, 17, 20].includes(firstTwoDigits)) {
    return false;
  }

  const coefficients = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];

  let sum = 0;
  for (let i = 0; i < coefficients.length; i++) {
    sum += rucDigits[i] * coefficients[i];
  }

  const remainder = sum % 11;
  const calculatedVerifierDigit = (11 - remainder) % 10;

  const verifierDigit = rucDigits[10];

  return calculatedVerifierDigit === verifierDigit;
}
