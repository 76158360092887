import { useEffect } from "react";
import Loader from "../Loader";
import LoginForm from "./LoginForm";
import Headerauth from "./components/Header";
import './login.css';
import { useAuthStore } from "../store/AuthStore";

function Login() {
    const setAccessTokenExpired = useAuthStore((state) => state.setAccessTokenExpired);

    useEffect(() => {
        setAccessTokenExpired(false);
    }, []);

    return (
        <main className="min-h-screen flex flex-col w-full login_container">
            <Loader />

            <Headerauth></Headerauth>
            <div className="flex sm:my-auto items-start md:items-center justify-center w-full">
                
                <LoginForm />
            </div>
        </main>
    );
}

export default Login;
