import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import FullTable from "@/components/ui/full-table";

import useTableColumns from "./hooks/useTableColumns";

import { Card } from "@/components/ui/card";
import { Separator } from "@/components/ui/separator";
import FilterPeriodSelect from "@/components/shared/period-filter-select";
import PaginationSizeSelect from "@/components/shared/pagination-size-select";
import { useDashboardStore } from "@/store/DashboardStore";
import usePagination from "@/hooks/use-pagination";
import Pagination from "@/components/ui/pagination/pagination";
import { formatNumber } from "../helpers";
import { Button } from "@/components/ui/button";
import { DownloadIcon } from "lucide-react";
import { DownloadAlertModal } from "./components/modals";
import useDownload, { FormatType } from "./hooks/useDownload";
import { ButtonWithTooltip } from "./components/buttonTooltip";
import icDocumentCheckDenied from "/assets/ic_document_check_denied.svg?url";

function ValidatedInvoices() {
  const {
    valuesFilterSelected: { tax_period },
    fetchValidatedReport,
    validatedReport: report,
  } = useDashboardStore((state) => state);

  const { columns, selectedRows, handleSelectAllChange, setSelectedRows } = useTableColumns(report?.vouchers);
  const { currentPage, pageSize, onChangePage, onChangePageSize } = usePagination();

  const [isOpen, setIsOpen] = useState(false);
  
  const toggleDropdown = () => {
    setIsOpen(prev => !prev);
  };

  const handleDownloadSelected = (type: FormatType) => {
    handleDownload(type);
    setIsOpen(false);
  };

  const { status, alertOpen, handleDownload, clearAlert } = useDownload({
    page : currentPage, 
    size: pageSize, 
    validateInvoiceCodesSelected: selectedRows, 
    validateInvoices: report?.vouchers
  });

  useEffect(() => {
    setSelectedRows([])
    fetchValidatedReport(currentPage, pageSize);
  }, [currentPage, pageSize, tax_period]);

  const img_cpe_link_processing = report?.vouchers.every((image: { img_cpe_link: string; xml_cpe_link: string; }) => 
    image.img_cpe_link === "" && image.xml_cpe_link === ""
  );

  // const xml_cpe_link_available = selectedRows.length === 0 || selectedRows.some(rowCode => {
  //   const row = report.vouchers.find((voucher: { code: string; }) => voucher.code === rowCode);
  //   return row && row.xml_cpe_link !== "";
  // });

  // const img_cpe_link_available = selectedRows.length === 0 || selectedRows.every(rowCode => {
  //   const row = report.vouchers.find((voucher: { code: string; }) => voucher.code === rowCode);
  //   return row && row.img_cpe_link !== "";
  // });

  
  return (
    <div className="my-6">
      <Link to="/inicio" className="flex items-center text-neutral-normal gap-1">
        <ChevronLeftIcon className="w-4 h-4" />
        <span className="text-sm f-secondary">Volver a listado de empresas</span>
      </Link>
      <div className="flex justify-between items-center">
        <h1 className="font-semibold text-neutral-dark text-2xl mt-6 mb-8">Facturas validadas</h1>
        <FilterPeriodSelect />
      </div>
      {report?.vouchers?.length > 0 ? (
        <>
          <div className="flex gap-6">
            <Card className="w-full max-w-60 bg-surface-200 py-4 px-5">
              <h3 className="text-sm font-bold mb-2">Monto total de IGV</h3>
              <div className="p text-xl">S/ {formatNumber(report?.summary?.total_igv_amount)}</div>
            </Card>
            <Card className="w-full max-w-60 bg-surface-100 py-4 px-5">
              <h3 className="text-sm font-bold mb-2">Total de facturas validadas</h3>
              <div className="p text-xl">{report?.summary?.total_items}</div>
            </Card>
          </div>
          <div className="flex flex-col sm:flex-row justify-between items-center">
            <div className="flex sm:order-1 w-full items-center md:justify-end gap-1 md:gap-4 my-4">
              <div className="w-1/2 sm:w-fit">
                <PaginationSizeSelect className="w-full" pageSize={pageSize} onChange={onChangePageSize} />
              </div>
              <ButtonWithTooltip 
                description="Descarga las representaciones gráficas de las facturas."
                className="max-w-52"
              >
                <div className="relative inline-block">
                  <Button 
                    onClick={toggleDropdown} 
                    loading={status === 'downloading'}
                    disabled={img_cpe_link_processing}
                  >
                    Descargar {selectedRows.length > 0 ? `(${selectedRows.length}) ` : 'todas las '} 
                    {selectedRows.length === 0 || selectedRows.length >= 2 ? 'facturas' : 'factura'} 
                    <DownloadIcon className="w-4 h-4 ml-2" />
                  </Button>
                  {isOpen && (
                    <div className="absolute right-0 mt-1 w-56 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg z-10">                    <div className="py-1">
                        <button
                          onClick={() => {
                            handleDownloadSelected('xml');
                            setIsOpen(false);
                          }}
                          className="text-gray-700 block w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
                        >
                          Formato XML
                        </button>
                          <button
                          onClick={() => {
                            handleDownloadSelected('jpeg');
                            setIsOpen(false);
                          }}
                          className="text-gray-700 block w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
                        >
                          Formato JPEG/PDF
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </ButtonWithTooltip>
              <DownloadAlertModal open={alertOpen} status={status} onClose={clearAlert} />
              { selectedRows.length > 0 && (
                <>
                  <Separator orientation="vertical" className="h-10 hidden sm:block" />
                  <span className="text-blue-normal hover:underline cursor-pointer text-sm hidden sm:inline" onClick={()=> handleSelectAllChange(false)}>Deseleccionar</span>
                </>
              )}            
            </div>
            {selectedRows.length > 0 && (
              <div className="w-full flex items-center justify-between sm:justify-start text-sm gap-1 my-4">
                <span className="text-neutral-dark inline sm:hidden">({selectedRows.length}) seleccionados</span>
                <span className="text-neutral-dark hidden sm:inline">{selectedRows.length} facturas seleccionadas</span>
                <Separator orientation="vertical" className="h-5" />
                <span className="text-blue-normal hover:underline cursor-pointer" onClick={() => handleSelectAllChange(true)}>
                  Seleccionar todo ({selectedRows.length})
                </span>
                <Separator orientation="vertical" className="h-5 sm:hidden" />
                <span className="text-blue-normal hover:underline cursor-pointer text-sm sm:hidden" onClick={()=> handleSelectAllChange(false)}>Deseleccionar</span>
              </div>
            )}
          </div>
          <FullTable columns={columns} data={report?.vouchers} />
          <Pagination
            currentPage={currentPage}
            pageCount={report.pagination.total_pages}
            onChangePage={onChangePage}
          />
        </>
      ) :
        <div className="my-6 flex flex-col justify-center items-center">
          <img src={icDocumentCheckDenied} alt="no hay data" />
          <p className="mt-5 text-center font-bold">No hay facturas validadas en el periodo</p>
          <p className="text-sm mt-3 text-center font-normal">Seleccione otro periodo</p>
        </div>
      }
    </div>
  );
}

export default ValidatedInvoices;
