
// Se obtiene los parámetros UTM de la URL
export function getUTMParams(url?: string): UTMParams {
  const currentUrl = url || window.location.href;
  const params = new URLSearchParams(new URL(currentUrl).search);

  const utms = {
    utm_source: params.get('utm_source'),
    utm_medium: params.get('utm_medium'),
    utm_campaign: params.get('utm_campaign'),
  };

  // Filtro de UTM's que no tienen valores
  const filteredUtms = Object.fromEntries(
    Object.entries(utms).filter(([_, value]) => value)
  );

  return filteredUtms as UTMParams;
}

