import { Button } from "@/components/ui/button";
import iconPadlockModal from "/assets/ic_padlock_shield_close_d.svg?url";
import { DialogTitle } from "@/components/ui/dialog";
import { useDashboardStore } from "@/store/DashboardStore";
import useSetBusiness from "@/hooks/use-set-business";

export function InvalidCredentials({ business }: { business: BusinessFilter }) {
  const { handleRedirectToDashboard } = useSetBusiness();

  const handleClick = () => {
    const filters = useDashboardStore.getState().filters;
    handleRedirectToDashboard(business, filters, "/dashboard/editar-credenciales");
  };

  return (
    <>
      <div className="text-center">
        <img src={iconPadlockModal} alt="Icono" className="inline-block" />
        <DialogTitle className="text-xl my-2 leading-7 font-medium">
          Credenciales inválidas
        </DialogTitle>
      </div>
      <div className="text-center space-y-4">
        <p className="text-sm">
          Al parecer las credenciales "Portal SOL" que ingresaste no son correctas y nos impidió
          procesar tu información.
        </p>
        <p className="text-sm">Actualiza tus credenciales para ver toda tu información:</p>
        <Button type="button" onClick={handleClick}>
          Actualizar credenciales
        </Button>
      </div>
    </>
  );
}
