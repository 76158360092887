import { ModalState } from '@/shared/Modal';
import { useCallback, useState, useEffect } from 'react';

export const useLoadPdf = (
    urlReport: string,
    modalState: string, 
    addLoadingTag: (tag: string) => void, 
    removeLoadingTag: (tag: string) => void
) => {
    const [isPdfLoaded, setIsPdfLoaded] = useState(false);
    const [base64Pdf, setBase64Pdf] = useState('');

    const loadPdf = useCallback(async () => {
        try {
            addLoadingTag('loading');
            if (urlReport.endsWith('.pdf')) {
                const base64 = await convertPdfToBase64(urlReport);
                setBase64Pdf(base64);
                setIsPdfLoaded(true);
                removeLoadingTag('loading');
            }
            
        } 
        catch (error) {
            console.error('Error al cargar el PDF:', error);
            removeLoadingTag('loading');
        }
    }, [urlReport, addLoadingTag, removeLoadingTag]);

    useEffect(() => {
        if (modalState === ModalState.OPEN) {
            setIsPdfLoaded(false);
            loadPdf();
        } else {
            removeLoadingTag('loading');
        }
    }, [modalState, loadPdf, removeLoadingTag]);

    return { isPdfLoaded, loadPdf, base64Pdf };
};

const convertPdfToBase64 = async (urlReport: string) => {
    const response = await fetch(urlReport);
    const blob = await response.blob();
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            if (reader.result) {
                const base64 = (reader.result as string).split(',')[1];
                resolve(base64);
            } else {
                reject(new Error("Error al convertir el PDF a Base64: el resultado es nulo."));
            }
        };
        reader.onerror = () => reject(new Error("Error al leer el archivo Blob."));
        reader.readAsDataURL(blob);
    });
};

export default useLoadPdf;

