import { ShieldCheckIcon } from "lucide-react";
import LogoCredicorp from '/assets/Credicorp-Logo.png'

export default function CredicorpFooter() {
    return (
        <p className="text-center flex flex-col md:flex-row items-center justify-center mx-auto text-gray-600 gap-1 mt-6">
            <span className="flex">
                <ShieldCheckIcon />
                Tus datos 100% seguros, con respaldo
            </span>
            <img src={LogoCredicorp} alt="Credicorp" className="max-w-32 object-contain" />
        </p>
    )
}