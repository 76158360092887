import { create } from "zustand";
import { useApplicationStore } from "./ApplicationStore";
import { useAuthStore } from "./AuthStore";
import axios from "axios";
import { createJSONStorage, persist } from "zustand/middleware";
import { ModalState } from "@/shared/Modal";
import { toast } from "@/hooks/use-toast";
import { ToastAction } from "@radix-ui/react-toast";
import { Report } from "@/dashboard/models/report.model";
import { SumaryPerceptions, SumaryRetentions } from "@/dashboard/models/sumary.model";

const LOCAL_API_BASE_URL = import.meta.env.VITE_API_BASE_URL || "http://localhost:3000";

interface ReportsSelected {
    code: string;
    reason: string;
}
interface DashboardState {
    accountant: Accountant;
    filters: Filters;
    valuesFilterSelected: ValuesSelected;
    dashboardData: DashboardData | null;
    reportsObserved: ReportsObserved | null;
    reportsRejected: ReportsObserved | null
    reportsPurchaseRetentions: Report<SumaryRetentions, ReportRetentions> | null;
    reportsPurchasePerceptions: Report<SumaryPerceptions, CpesPerceptions> | null;
    detractionsReport: any | null; // TODO agregar tipado
    validatedReport: any | null;
    rowDetractionSelected: any | null;
    setValuesFilterSelected: (values: any) => void;
    setReportsObserved: (reportsObserved: ReportsObserved) => void;
    validateReportObserved: (reportsSelected: ReportsSelected[]) => Promise<void>;
    declineReportObserved: (reportsSelected: ReportsSelected[]) => Promise<void>;
    editCredentials: (body: any, uuid: string, reset: any) => void;
    fetchFiltersBusiness: () => Promise<null | Filters>;
    fetchFiltersPeriods: (businessUuid: string) => Promise<null | Filters>;
    fetchDashboardData: () => Promise<void>;
    fetchDashboardReportsObserved: (page: number, page_record: number) => Promise<void>;
    fetchReportRetentions: (page: number, size: number) => Promise<void>;
    fetchReportPerceptions: (page: number, size: number) => Promise<void>;
    fetchDetractionsReport: (paid: boolean|string, page: number|string, page_record: number|string) => Promise<void>;
    fetchValidatedReport: (page: number, size: number) => Promise<void>;
    fetchDashboardReportsRejected: (currentPage: number, pageSize: number) => Promise<void>;
    fetchValidateReportImagesZip: (page: number, size: number, codigosFacturas: string[], type: string)=> Promise<string>;
    resetFilters: () => void;
    resetReportsObserved: () => void;
    resetReportsPerceptions: () => void;
    resetDashboardData: () => void;
}

function handleErrorResponse(error: unknown, setAccessTokenExpired: (expired: boolean) => void): void {
    if (axios.isAxiosError(error)) {
        switch (error.response?.status) {
            case 401:
                setAccessTokenExpired(true);
                throw new Response(error.message, { status: 401, statusText: error.message });
            case 404:
                throw new Response(error.message, { status: 404, statusText: error.message });
            default:
                throw new Response(error.message, { status: 500, statusText: error.message });
        }
    } else {
        throw new Response('Unexpected error occurred', { status: 500 });
    }
}

export const useDashboardStore = create<DashboardState>()(
    persist(
        // type: ignore
        // @ts-ignore
        (set, get) => ({
            accountant: {
                name: "",
            },
            filters: {
                tax_periods: [],
                businesses: [],
            },
            valuesFilterSelected: {
                ruc: "",
                tax_period: "",
                uuid: "",
                paid: true,
                page: 1,
                page_record: 2
            },
            rowDetractionSelected: null,
            dashboardData: null,
            reportsObserved: null,
            reportsPurchasePerceptions: null,
            detractionsReport: null,
            validatedReport: null,
            isModalDetractionOpen: ModalState.CLOSE,
            setValuesFilterSelected(valuesFilterSelected) {
                set({ valuesFilterSelected });
            },
            setReportsObserved(reportsObserved: ReportsObserved) {
                set({ reportsObserved });
            },
            async validateReportObserved(reportsSelected: ReportsSelected[]) {
                const { ruc, tax_period } = get().valuesFilterSelected;
                const token = useAuthStore.getState().accessToken;
                const { setAccessTokenExpired } = useAuthStore.getState();

                try {
                    await axios.patch(
                        `${LOCAL_API_BASE_URL}/app/internal/businesses/dashboard-reports/observed/validate/?ruc=${ruc}&tax_period=${tax_period}`,
                        {
                            cpes: reportsSelected
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );
                } catch (error) {
                    if (axios.isAxiosError(error)) {
                        if (error.response?.status === 401) {
                            setAccessTokenExpired(true);
                            throw new Response(error as any, {
                                status: 401,
                                statusText: error.message,
                            });
                        }

                        // Throwing 404 error
                        throw new Response(error as any, {
                            status: 404,
                            statusText: error.message,
                        });
                    } else {
                        // Throw 500 error
                        throw new Response(error as any, { status: 500 });
                    }
                }

                return Promise.resolve();
            },
            async editCredentials(body: any, uuid: string, reset) {
                const token = useAuthStore.getState().accessToken;
                const { setAccessTokenExpired } = useAuthStore.getState();
                try {
                    await axios.patch(
                        `${LOCAL_API_BASE_URL}/app/internal/businesses/${uuid}/credentials`,
                        body,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        },
                    ).then(() => {
                        toast({
                              description: "Credenciales actualizadas correctamente!",
                              action: (
                                <ToastAction altText="x">x</ToastAction>
                              ),
                            })
                        reset()
                    })
                } catch (error) {
                    if (axios.isAxiosError(error)) {
                        if (error.response?.status === 401) {
                            setAccessTokenExpired(true);
                            throw new Response(error as any, {
                                status: 401,
                                statusText: error.message,
                            });
                        }

                        // Throwing 404 error
                        throw new Response(error as any, {
                            status: 404,
                            statusText: error.message,
                        });
                    } else {
                        // Throw 500 error
                        throw new Response(error as any, { status: 500 });
                    }
                }
            },
            async declineReportObserved(reportsSelected: ReportsSelected[]) {
                const { ruc, tax_period } = get().valuesFilterSelected;
                const token = useAuthStore.getState().accessToken;
                const { setAccessTokenExpired } = useAuthStore.getState();

                try {
                    await axios.patch(
                        `${LOCAL_API_BASE_URL}/app/internal/businesses/dashboard-reports/observed/decline/?ruc=${ruc}&tax_period=${tax_period}`,
                        {
                            cpes: reportsSelected
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            }
                        },
                    );
                } catch (error) {
                    if (axios.isAxiosError(error)) {
                        if (error.response?.status === 401) {
                            setAccessTokenExpired(true);
                            throw new Response(error as any, {
                                status: 401,
                                statusText: error.message,
                            });
                        }

                        // Throwing 404 error
                        throw new Response(error as any, {
                            status: 404,
                            statusText: error.message,
                        });
                    } else {
                        // Throw 500 error
                        throw new Response(error as any, { status: 500 });
                    }
                }

                return Promise.resolve();
            },
            async fetchFiltersBusiness() {
                useApplicationStore.getState().addLoadingTag("LOADING_FETCH_FILTERS_BUSINESS");

                const token = useAuthStore.getState().accessToken;
                const { setAccessTokenExpired } = useAuthStore.getState();

                let promiseResponse: Filters | null = null;

                try {
                    const response = await axios.get(
                        `${LOCAL_API_BASE_URL}/app/internal/accountant/dashboard/filters`,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        },
                    );

                    let { businesses } = response.data;

                    set( (state) => ({ filters: { ...state.filters, businesses } }))
                    
                    const filters = get().filters

                    promiseResponse = { ...filters };
                } catch (error) {
                    handleErrorResponse(error, setAccessTokenExpired)
                } finally {
                    useApplicationStore.getState().removeLoadingTag("LOADING_FETCH_FILTERS_BUSINESS");
                }

                return Promise.resolve(promiseResponse);
            },
            async fetchFiltersPeriods(businessUuid) {
                useApplicationStore.getState().addLoadingTag("LOADING_FETCH_FILTERS_PERIODS");

                const token = useAuthStore.getState().accessToken;
                const { setAccessTokenExpired } = useAuthStore.getState();

                let promiseResponse: Filters | null = null;

                try {
                    const response = await axios.get(
                        `${LOCAL_API_BASE_URL}/app/internal/businesses/${businessUuid}/periods`,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        },
                    );

                    let { tax_periods } = response.data;

                    set( (state) => ({ filters: { ...state.filters, tax_periods } }))
                    
                    const filters = get().filters

                    promiseResponse = { ...filters };

                } catch (error) {
                    handleErrorResponse(error, setAccessTokenExpired)
                } finally {
                    useApplicationStore.getState().removeLoadingTag("LOADING_FETCH_FILTERS_PERIODS");

                }

                return Promise.resolve(promiseResponse);

            },
            async fetchDashboardData() {
                const { ruc, tax_period, uuid } = get().valuesFilterSelected;
                useApplicationStore.getState().addLoadingTag("LOADING_FETCH_DASHBOARD_DATA");
                const token = useAuthStore.getState().accessToken;
                const { setAccessTokenExpired } = useAuthStore.getState();
                
                const filters = {
                    ruc,
                    tax_period,
                    business_uuid: uuid
                };

                try {
                    const response = await axios.get(
                        `${LOCAL_API_BASE_URL}/app/internal/accountant/dashboard?filters=${JSON.stringify(filters)}`,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        },
                    );
                    const dashboardData = response.data;
                    
                    setAccessTokenExpired(false);
                    set({ dashboardData });
                    
                } catch (error) {
                    if (axios.isAxiosError(error)) {
                        if (error.response?.status === 401) {
                            setAccessTokenExpired(true);
                            throw new Response(error as any, {
                                status: 401,
                                statusText: error.message,
                            });
                        }

                        // Throwing 404 error
                        throw new Response(error as any, {
                            status: 404,
                            statusText: error.message,
                        });
                    } else {
                        // Throw 500 error
                        throw new Response(error as any, { status: 500 });
                    }
                } finally {
                    useApplicationStore.getState().removeLoadingTag("LOADING_FETCH_DASHBOARD_DATA");
                }
                return Promise.resolve();
            },
            async fetchDashboardReportsObserved(page, page_record) {
                const { ruc, tax_period } = get().valuesFilterSelected;
                useApplicationStore.getState().addLoadingTag("LOADING_FETCH_DASHBOARD_REPORTS_OBSERVED");
                const token = useAuthStore.getState().accessToken;
                const { setAccessTokenExpired } = useAuthStore.getState();

                try {
                    const {data} = await axios.get(
                        `${LOCAL_API_BASE_URL}/app/internal/businesses/dashboard-reports/observed?ruc=${ruc}&tax_period=${tax_period}&page=${page}&size=${page_record}`,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        },
                    );

                    set({
                        reportsObserved: {
                            ...data,
                            cpes: data.data?.vouchers.map((cpes: Cpes) => ({
                                ...cpes,
                                checked: false
                            }))
                        }
                    });
                } catch(error) {
                    if (axios.isAxiosError(error)) {
                        if (error.response?.status === 401) {
                            setAccessTokenExpired(true);
                            throw new Response(error as any, {
                                status: 401,
                                statusText: error.message,
                            });
                        }

                        // Throwing 404 error
                        throw new Response(error as any, {
                            status: 404,
                            statusText: error.message,
                        });
                    } else {
                        // Throw 500 error
                        throw new Response(error as any, { status: 500 });
                    }
                } finally {
                    useApplicationStore.getState().removeLoadingTag("LOADING_FETCH_DASHBOARD_REPORTS_OBSERVED");
                }
                return Promise.resolve();
            },
            async fetchDashboardReportsRejected(currentPage, pageSize) {
                const { ruc, tax_period } = get().valuesFilterSelected;
                useApplicationStore.getState().addLoadingTag("LOADING_FETCH_DASHBOARD_REPORTS_REJECTED");
                const token = useAuthStore.getState().accessToken;
                const { setAccessTokenExpired } = useAuthStore.getState();

                try {
                    const {data} = await axios.get(
                        `${LOCAL_API_BASE_URL}/app/internal/businesses/dashboard-reports/rejected?ruc=${ruc}&tax_period=${tax_period}&page=${currentPage}&page_record=${pageSize}`,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        },
                    );
                    
                    set({
                        reportsRejected: {
                            ...data,
                            cpes: data?.data?.vouchers.map((cpes: Cpes) => ({
                                ...cpes,
                                checked: false
                            }))
                        }
                    });
                } catch(error) {
                    if (axios.isAxiosError(error)) {
                        if (error.response?.status === 401) {
                            setAccessTokenExpired(true);
                            throw new Response(error as any, {
                                status: 401,
                                statusText: error.message,
                            });
                        }

                        // Throwing 404 error
                        throw new Response(error as any, {
                            status: 404,
                            statusText: error.message,
                        });
                    } else {
                        // Throw 500 error
                        throw new Response(error as any, { status: 500 });
                    }
                } finally {
                    useApplicationStore.getState().removeLoadingTag("LOADING_FETCH_DASHBOARD_REPORTS_REJECTED");
                }
                return Promise.resolve();
            },
            async fetchReportPerceptions(page, size) {
                const { ruc, tax_period, uuid } = get().valuesFilterSelected;
                useApplicationStore.getState().addLoadingTag("LOADING_FETCH_REPORTS_PERCEPTIONS");
                const token = useAuthStore.getState().accessToken;
                const { setAccessTokenExpired } = useAuthStore.getState();

                try {
                    const { data } = await axios.get(
                        `${LOCAL_API_BASE_URL}/app/internal/businesses/dashboard-reports/perceptions`,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                            params: {
                                business_ruc: ruc,
                                business_uuid: uuid,
                                tax_period,
                                page,
                                size
                            }
                        },
                    );

                    if (data) {
                        set({ reportsPurchasePerceptions: data});
                    } else {
                        throw new Error("No data found");
                    }

                } catch(error) {
                    if (axios.isAxiosError(error)) {
                        if (error.response?.status === 401) {
                            setAccessTokenExpired(true);
                            throw new Response(error as any, {
                                status: 401,
                                statusText: error.message,
                            });
                        }

                        // Throwing 404 error
                        throw new Response(error as any, {
                            status: 404,
                            statusText: error.message,
                        });
                    } else {
                        // Throw 500 error
                        throw new Response(error as any, { status: 500 });
                    }
                } finally {
                    useApplicationStore.getState().removeLoadingTag("LOADING_FETCH_REPORTS_PERCEPTIONS");
                }
                return Promise.resolve();
            },
            async fetchReportRetentions(page, size) {
                const { ruc, tax_period, uuid } = get().valuesFilterSelected;
                useApplicationStore.getState().addLoadingTag("LOADING_FETCH_REPORT_RETENTIONS");
                const token = useAuthStore.getState().accessToken;
                const { setAccessTokenExpired } = useAuthStore.getState();

                try {
                    const { data } = await axios.get(
                        `${LOCAL_API_BASE_URL}/app/internal/businesses/dashboard-reports/retentions`,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                            params: {
                                business_ruc: ruc,
                                business_uuid: uuid,
                                tax_period,
                                page,
                                size
                            }
                        },
                    );

                    if (data) {
                        set({ reportsPurchaseRetentions: data});
                    } else {
                        throw new Error("No data found");
                    }
                   
                } catch(error) {
                    if (axios.isAxiosError(error)) {
                        if (error.response?.status === 401) {
                            setAccessTokenExpired(true);
                            throw new Response(error as any, {
                                status: 401,
                                statusText: error.message,
                            });
                        }

                        // Throwing 404 error
                        throw new Response(error as any, {
                            status: 404,
                            statusText: error.message,
                        });
                    } else {
                        // Throw 500 error
                        throw new Response(error as any, { status: 500 });
                    }
                } finally {
                    useApplicationStore.getState().removeLoadingTag("LOADING_FETCH_REPORT_RETENTIONS");
                }
                return Promise.resolve();
            },
            async fetchDetractionsReport(paid, page, size) {
                const { ruc, tax_period, uuid } = get().valuesFilterSelected;
                useApplicationStore.getState().addLoadingTag("LOADING_FETCH_DETRACTIONS_REPORTS");
                const token = useAuthStore.getState().accessToken;
                const { setAccessTokenExpired } = useAuthStore.getState();

                try {
                    const { data } = await axios.get(
                        `${LOCAL_API_BASE_URL}/app/internal/businesses/dashboard-reports/detractions`,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                            params: {
                                ruc,
                                business_uuid: uuid,
                                tax_period,
                                page,
                                size,
                                paid
                            }
                        },
                    );

                    if (data) {
                        set({ detractionsReport: {...data.data, summary: data.summary } });
                    } else {
                        throw new Error("No data found");
                    }
                } catch(error) {
                    handleErrorResponse(error, setAccessTokenExpired);
                } finally {
                    useApplicationStore.getState().removeLoadingTag("LOADING_FETCH_DETRACTIONS_REPORTS");
                }
                return Promise.resolve();
            },
            async fetchValidatedReport(page, size) {
                const { ruc, tax_period, uuid } = get().valuesFilterSelected;
                useApplicationStore.getState().addLoadingTag("LOADING_FETCH_VALIDATED_REPORT");
                const token = useAuthStore.getState().accessToken;
                const { setAccessTokenExpired } = useAuthStore.getState();

                try {
                    const { data } = await axios.get(
                        `${LOCAL_API_BASE_URL}/app/internal/businesses/dashboard-reports/validated`,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                            params: {
                                ruc,
                                uuid,
                                tax_period,
                                page,
                                size
                            }
                        },
                    );

                    if (data) {
                        set({ validatedReport: {...data.data, summary: data.summary }});
                    } else {
                        throw new Error("No data found");
                    }
                } catch(error) {
                    handleErrorResponse(error, setAccessTokenExpired);
                } finally {
                    useApplicationStore.getState().removeLoadingTag("LOADING_FETCH_VALIDATED_REPORT");
                }
                return Promise.resolve();
            },
            async fetchValidateReportImagesZip(page, size, codigosFacturas, type) {

                const { ruc, tax_period } = get().valuesFilterSelected;

                const token = useAuthStore.getState().accessToken;

                const { setAccessTokenExpired } = useAuthStore.getState();

                try {

                    const { data } = await axios.post(
                        `${LOCAL_API_BASE_URL}/app/internal/businesses/dashboard-reports/validated-images`,
                        {
                            ruc,
                            tax_period,
                            page,
                            size,
                            invoiceCodes: codigosFacturas,
                            type
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        },

                    );

                   return data.zip_file

                } catch(error) {

                    handleErrorResponse(error, setAccessTokenExpired);

                }

                return Promise.resolve();
            },
            async resetFilters() {
                set({
                    filters: {
                        tax_periods: [],
                        businesses: [],
                    },
                    valuesFilterSelected: {
                        ruc: "",
                        tax_period: "",
                        uuid: ""
                    },
                });
            },
            async resetReportsObserved() {
                set({
                    reportsObserved: null
                });
            },
            async resetReportsPerceptions() {
                set({
                    reportsPurchasePerceptions: null
                });
            },
            async resetDashboardData() {
                set({
                    dashboardData: null
                });
            }
        }),
        {
            name: "dashboard-store",
            storage: createJSONStorage(() => sessionStorage),
            version: 2,
            partialize: (state) => ({ filters: state.filters, valuesFilterSelected: state.valuesFilterSelected }),
        },
    ),
);
