type InputPattern = 'letters' | 'numbers';

export default function handleInputChange (pattern: InputPattern) {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        let patternsInput: string;
        switch (pattern) {
            case 'letters':
                patternsInput = value.replace(/[^a-zA-Z ]/g, '');
                break;
            case 'numbers':
                patternsInput = value.replace(/[^0-9]/g, '');
                break;
            default:
                patternsInput = value;
                break;
        }
        e.target.value = patternsInput;
    };
}