import { Fragment, ReactNode, useState } from "react";

interface TooltipProps {
  text: string;
  children: ReactNode;
}

export const TooltipObservation = ({ text, children }: TooltipProps) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const formattedText = text.split("\n").map((line, index) => (
    <Fragment key={index}>
      {line}
      <br />
    </Fragment>
  ));

  return (
    <div
      className="pl-2 relative flex items-center"
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      <div className="inline-block">{children}</div>
      {showTooltip && (
        <div className="absolute top-6 -left-40 z-10 p-2 text-xs font-normal leading-tight text-white bg-slate-800 rounded whitespace-no-wrap tooltip w-56">
          {formattedText}
          <div className="absolute w-3 h-3 bg-slate-800 transform rotate-45 -top-1 left-1/2"></div>
        </div>
      )}
    </div>
  );
};
