import {
  Pagination as PaginationContainer,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination/pagination-elements";

export interface PaginationProps {
  currentPage: number;
  pageCount: number;
  // pageSize?: number;
  // totalItems?: number;
  onChangePage: (page: number) => void;
}
export default function Pagination({ currentPage, pageCount, onChangePage }: PaginationProps) {
  const nextPage = () => {
    if (currentPage < pageCount) {
      onChangePage(currentPage + 1);
    }
  };

  const previousPage = () => {
    if (currentPage > 1) {
      onChangePage(currentPage - 1);
    }
  };

  const renderPageNumbers = () => {
    const pages = [];

    if (pageCount <= 5) {
      // If there are 5 or fewer pages, show all of them
      for (let i = 1; i <= pageCount; i++) {
        pages.push(
          <PaginationItem key={i} onClick={() => onChangePage(i)}>
            <PaginationLink isActive={i === currentPage}>{i}</PaginationLink>
          </PaginationItem>,
        );
      }
    } else {
      // Show first three pages, ellipsis, and last two pages
      pages.push(
        <PaginationItem key={1} onClick={() => onChangePage(1)}>
          <PaginationLink isActive={1 === currentPage}>1</PaginationLink>
        </PaginationItem>,
      );

      if (currentPage > 3) {
        pages.push(<PaginationEllipsis key="ellipsis1" />);
      }

      const startPage = Math.max(2, currentPage - 1);
      const endPage = Math.min(pageCount - 1, currentPage + 1);

      for (let i = startPage; i <= endPage; i++) {
        pages.push(
          <PaginationItem key={i} onClick={() => onChangePage(i)}>
            <PaginationLink isActive={i === currentPage}>{i}</PaginationLink>
          </PaginationItem>,
        );
      }

      if (currentPage < pageCount - 2) {
        pages.push(<PaginationEllipsis key="ellipsis2" />);
      }

      pages.push(
        <PaginationItem key={pageCount} onClick={() => onChangePage(pageCount)}>
          <PaginationLink isActive={pageCount === currentPage}>{pageCount}</PaginationLink>
        </PaginationItem>,
      );
    }

    return pages;
  };

  return (
    <PaginationContainer className="justify-end my-5">
      <PaginationContent>
        {currentPage > 1 && (
          <PaginationItem>
            <PaginationPrevious onClick={previousPage} />
          </PaginationItem>
        )}
        {renderPageNumbers()}
        {currentPage < pageCount && (
          <PaginationItem>
            <PaginationNext onClick={nextPage} />
          </PaginationItem>
        )}
      </PaginationContent>
    </PaginationContainer>
  );
}
