import { useDashboardStore } from "@/store/DashboardStore";
import { useEffect, useState } from "react";
import { ValidatedMasiveInvoiceFormProps } from "../models/validatedMasiveInvoiceForm";
import { ModalState } from "@/shared/Modal";
import { TextArea } from "./TextArea";

const ValidatedAllSelectInvoiceForm = ({
  cpes,
  setModalState,
  index,
}: ValidatedMasiveInvoiceFormProps) => {
  const [text, setText] = useState<string[]>(cpes.map(() => ""));
  const [isChecked, setIsChecked] = useState<boolean>(true);
  const { validateReportObserved } = useDashboardStore((state) => state);

  useEffect(() => {
    let updateTexts: string[] = [];

    if (isChecked) {
      updateTexts = cpes.map(() => text[index]);
    } else {
      updateTexts = cpes.map(() => "");
      updateTexts[index] = text[index];
    }

    setText(updateTexts);
  }, [isChecked]);

  const handleSetTextArea = (value: string) => {
    if (!isChecked) {
      const updateCorrecionTexts = [...text];
      updateCorrecionTexts[index] = value;
      setText(updateCorrecionTexts);
    } else {
      setText(cpes.map(() => value));
    }
  };

  const onClickValidateReport = async () => {
    try {
      setModalState(ModalState.LOADING);
      const body: any[] = [];

      cpes.forEach((cpe, index) => {
        body.push({
          code: cpe.code,
          reason: text[index],
        });
      });

      await validateReportObserved(body);

      setModalState(ModalState.DONE);
    } catch {
      setModalState(ModalState.ERROR);
      console.error("Aprobe report error");
    }
  };

  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <div className="text-left p-6">
      <h3 className="text-sm font-bold mb-1">Observación</h3>
      <p className="text-sm font-normal">{cpes[index].observation}</p>
      <hr className="my-3" />
      <h3 className="text-sm mb-3">Indícanos la correción de la observación (Opcional)</h3>
      <div>
        <TextArea
          maxLength={100}
          valueTextArea={text[index]}
          setValueTextArea={handleSetTextArea}
        ></TextArea>
      </div>

      <div className="mt-4 flex gap-2 flex-col sm:flex-row sm:justify-between">
        <div className="flex items-center">
          <input
            type="checkbox"
            id="checkAll"
            checked={isChecked}
            onChange={handleOnChange}
            className="w-5 h-5 checked:accent-slate-900 cursor-pointer"
          ></input>
          <label className="ml-2 pl-1 text-sm" htmlFor="checkAll">
            Aplicar a todos
          </label>
        </div>

        <div className="sm:w-1/2 flex gap-1 flex-col sm:flex-row mt-3 sm:mt-0">
          <div className="sm:order-2 sm:w-1/2">
            <button
              className="rounded-[20px] w-full mb-3 font-semibold sm:mb-0 px-3 py-2 bg-neutral-dark text-white"
              onClick={() => onClickValidateReport()}
            >
              Validar
            </button>
          </div>
          <div className="sm:order-1 sm:w-1/2">
            <button
              className="rounded-[20px] font-semibold w-full px-8 py-2 border border-neutral-darkest"
              onClick={() => setModalState(ModalState.CLOSE)}
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValidatedAllSelectInvoiceForm;
