import { useNavigate } from "react-router";
import * as Yup from "yup";
import Loader from "../Loader";
import ErrorAlert from "../errors/ErrorAlert";
import Headerauth from "./components/Header";
import { useAuthStore } from "../store/AuthStore";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomInput from "./components/CustomInput";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import doneImg from "/assets/spc_mobile_success_hand_d_l.svg?url";

interface RecoverPswForm {
    password: string;
    confirm_password: string;
}

const validationSchema = Yup.object().shape({
    password: Yup.string()
        .min(6, "La contraseña debe de tener como mínimo 6 caracteres")
        .matches(/^(?=.*[a-z])(?=.*[A-Z]).*$/, {
            message: "La contraseña debe contener al menos una mayúscula y una minúscula",
            excludeEmptyString: true,
        })
        .required("Ingrese su contraseña"),
    confirm_password: Yup.string()
        .oneOf([Yup.ref("password"), ""], "Su contraseña no coincide")
        .required("Vuelva a ingresar su contraseña"),
});

const ErrorToken = () => {
    const navigate = useNavigate();

    const onClickButton = () => {
        navigate('/recuperar-contraseña-identificacion');
    }

    return (
        <div className="flex sm:my-auto items-start md:items-center justify-center w-full">
            <div className="container">
                <div className="px-5 mt-8 sm:mt-0 sm:px-8 sm:card-form max-w-xl border-gray flex sm:min-h-[354px] justify-center flex-col">
                     <div className="text-center mt-4">
                        <h1 className="font-bold text-2xl">Este link ya expiró</h1>
                        <p className="text-sm mt-2">Vuelve a colocar tu correo para recuperar tu cuenta.</p>
                    </div>

                    <div className="mt-5 text-center">
                        <button onClick={() => onClickButton()} type="button" className="primary-button-2 rounded-[20px] py-3 px-5">
                        Volver a enviar correo
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

const ChangePswSuccess = () => {
    const navigate = useNavigate();

    const onClickButton = () => {
        navigate('/login');
    }

    return (
        <div className="flex sm:my-auto items-start md:items-center justify-center w-full min-h-[354px]">
            <div className="container">
                <div className="px-5 mt-8 sm:mt-0 sm:px-8 sm:card-form max-w-xl border-gray block">
                    <div className="text-center">
                        <img className="inline-block" src={doneImg} alt="Listo" />
                    </div>

                    <div className="text-center mt-4">
                        <h1 className="font-bold text-3xl">¡Listo!</h1>
                        <p className="text-base mt-2">Tu contraseña se ha actualizado correctamente.</p>
                    </div>

                    <div className="mt-5 text-center">
                        <button onClick={() => onClickButton()} type="button" className="primary-button-2 py-3 px-5">
                            Iniciar sesión
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}


function RecoverPsw() {
    const navigate = useNavigate();
    const location = useLocation();
    const errorMessages: ErrorMessage[] = useAuthStore((state) => state.errorMessages);
    const [changePswSuccess, setChangePswSuccess] = useState(false);
    const [errorToken, setErrorToken] = useState(false);
    const { validateRecoveryToken, updatePsw } = useAuthStore(state => state);

    useEffect(() => {
        const [, , token] = location.pathname.split('/');

        if(!token) {
            navigate('/recuperar-contraseña-identificacion');
            return;
        };

        validateRecoveryToken(token)
            .then(() => console.log('Token valido'))
            .catch((error) => {
                console.log(error, 'Error with validate token');
                setErrorToken(true);
            })
    }, [location]);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<RecoverPswForm>({
        resolver: yupResolver(validationSchema),
        mode: 'onBlur'
    });

    const onSubmit = (data: RecoverPswForm) => {

        updatePsw(data.password)
            .then(() => {
                console.log('Change psw success');
                setChangePswSuccess(true);
            })
            .catch(error => console.log('Change psw error', error));
    };

    return (
        <main className="min-h-screen flex flex-col w-full login_container">
            <Loader />
            <Headerauth />
    
            {/* Mostrar formulario si el token es válido y no hay error de token */}
            {!changePswSuccess && !errorToken && (
                <div className="flex sm:my-auto items-start md:items-center justify-center w-full">
                    <div className="container">
                        <ErrorAlert errorMessages={errorMessages} />
    
                        <form
                            action="#"
                            onSubmit={handleSubmit(onSubmit)}
                            method="POST"
                            className="px-5 mt-5 sm:mt-0 sm:px-8 sm:card-form max-w-xl border-gray block"
                        >
                            <div className="text-center">
                                <h1 className="text-2xl font-bold mb-1">Nueva contraseña</h1>
                                <p className="text-sm mb-3">Ingresa una nueva contraseña para tu cuenta ContaPro.</p>
                            </div>
    
                            <CustomInput<RecoverPswForm>
                                id="password"
                                labelInput="Contraseña"
                                type="password"
                                placeholder="Ingrese su contraseña"
                                register={register}
                                errorMessage={errors.password?.message}
                            />
    
                            <CustomInput<RecoverPswForm>
                                id="confirm_password"
                                labelInput="Repite la contraseña"
                                type="password"
                                placeholder="Ingrese nuevamente su contraseña"
                                register={register}
                                errorMessage={errors.confirm_password?.message}
                            />
    
                            <div className="mt-5">
                                <button type="submit" className="w-full primary-button-2 py-3 px-4">
                                    Crear nueva contraseña
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
    
            {/* Mostrar componente de éxito o error según corresponda */}
            {(changePswSuccess && <ChangePswSuccess />) || (errorToken && <ErrorToken />)}
        </main>
    );
}

export default RecoverPsw;
