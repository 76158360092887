import { CopyIcon } from "lucide-react";
import { formatNumber } from "../helpers";
import { useToast } from "@/hooks/use-toast";

interface CopyAmountButtonProps {
  amount: number;
}

export default function CopyAmountButton({ amount }: CopyAmountButtonProps) {
  const { toast } = useToast();

  const handleClick = () => {
    navigator.clipboard.writeText(formatNumber(amount));
    toast({ description: 'Copiado al portapapeles' });
  }

  return (
    <p className="flex items-center gap-1">
      <span className="text-2xl text-primary-black">s/ {formatNumber(amount)}</span>
      <CopyIcon className="w-4 h-4 text-blue-normal cursor-pointer" onClick={handleClick} />
    </p>
  );
}
