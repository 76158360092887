import { businessStatuses } from "@/enums";
import { FileX2 } from "lucide-react";
import { Link } from "react-router-dom";
import { useBusinessStore } from "@/store/BusinessStore";

const contentByStatus = {
  [businessStatuses.INVALID_CREDENTIALS]: {
    title: "Este usuario SOL tiene credenciales inválidas.",
    description: <>Corrígelas en la opción de  <Link className="underline" to="editar-credenciales">Editar credenciales</Link>.</>,
  },
  [businessStatuses.MISSING_PERMISSIONS]: {
    title: "Faltan permisos de SUNAT para acceder a toda la información.",
    description: "Asigna el permiso correspondiente a la cuenta para poder mostrar tus datos correctamente.",
  },
  [businessStatuses.IN_PROGRESS]: null,
  [businessStatuses.VALID]: null,
};

function BusinessErrorAlert() {
  const { status } = useBusinessStore(state => state.business);
  const { title, description } = contentByStatus[status!] || {};
  
  return (
    <div className="flex bg-danger-lightest rounded-2xl gap-2 p-5 my-8">
      <div className="flex justify-center items-center bg-white rounded-full w-14 h-14">
        <FileX2 className="w-7 h-7 text-danger-darkest" />
      </div>
      <div>
        <h3 className="font-bold">{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
}

export default BusinessErrorAlert;
