import CardLarge from '../../shared/CardLarge.tsx';
import CardSmall from '../../shared/CardSmall.tsx';
import { useNavigate } from 'react-router-dom';
import CopyAmountButton from './Button.tsx';

interface PurchasePDTProps {
	purchase: Purchase;
	sum_retention: number;
	sum_perception: number;
	businessInfo: BusinessInfo;
}

interface BusinessInfo {
	ruc: string;
	businessName: string;
}


function PurchasePDT({ purchase, sum_perception, sum_retention, businessInfo }: PurchasePDTProps) {
	const navigate = useNavigate();

	const linkToRetentions = () => {
		const newUrl = `/dashboard/retenciones`;
		navigate(newUrl, {
			state: {
				page: 'retenciones',
				...businessInfo
			},
		});
	}

	const linkToPercepciones = () => {
		const newUrl = `/dashboard/percepciones`;
		navigate(newUrl, {
			state: {
				page: 'compras-percepciones',
				...businessInfo
			}
		});
	}

	return (
		<div className="mt-5">
			<h2 className="text-xl font-semibold text-primary-black mb-3">Resumen IGV</h2>

			<div className="flex flex-col md:flex-row justify-between gap-5 pb-4">
				<div className="flex-none h-auto max-sm:w-full">
					<CardLarge title="Importe no gravado">
						<p className="text-sm f-secondary font-semibold text-neutral-normal mt-3">Monto total</p>
						<CopyAmountButton amount={purchase.non_taxable_amount} />
					</CardLarge>
				</div>
				<div className="h-auto w-1/2 max-md:w-full">
					<CardLarge title="IGV 10%">
						<div className="flex gap-5 mt-3">
							<div>
								<p className="text-sm f-secondary font-semibold text-neutral-normal">Base Imponible</p>
								<CopyAmountButton amount={purchase.igv_10_taxable_amount} />
							</div>
							<div>
								<p className="text-sm f-secondary font-semibold text-neutral-normal">Monto de IGV</p>
								<CopyAmountButton amount={purchase.igv_10_amount} />
							</div>
						</div>
					</CardLarge>
				</div>
				<div className="h-auto w-1/2 max-md:w-full">
					<CardLarge title="IGV 18%">
						<div className="flex gap-5 mt-3">
							<div>
								<p className="text-sm f-secondary font-semibold text-neutral-normal">Base Imponible</p>
								<CopyAmountButton amount={purchase.igv_18_taxable_amount} />
							</div>
							<div>
								<p className="text-sm f-secondary font-semibold text-neutral-normal">Monto de IGV</p>
								<CopyAmountButton amount={purchase.igv_18_amount} />
							</div>
						</div>
					</CardLarge>
				</div>
			</div>

			<h2 className="text-xl font-semibold text-primary-black mb-3">Adicionales</h2>

			<div className="w-full md:w-2/5 flex gap-5 mb-4 lg:mb-0">
				<div className="bg-white h-auto w-full lg:w-[50%] max-sm:w-1/2">
					<CardSmall
						primaryNumber={sum_perception}
						caption="Percepciones"
						reverse={ true }
						linkTo={ linkToPercepciones }
					/>
				</div>
				<div className="bg-white h-full w-full lg:w-[50%] max-sm:w-1/2">
					<CardSmall primaryNumber={sum_retention} caption="Retenciones"
					           reverse={ true } linkTo={ linkToRetentions }/>
				</div>
			</div>
		</div>
	);
}

export default PurchasePDT;
