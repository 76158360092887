import axios from "axios";

export function registerAccountant(user: User) {
    const form_id = import.meta.env.VITE_CUSTOMERIO_FORM_ID;
    const api_url = `https://track.customer.io/api/v1/forms/${form_id}/submit`;

    const credentials = import.meta.env.VITE_CUSTOMERIO_CREDENTIALS;
    const authHeader = `Basic ${btoa(credentials)}`;

    const config = {
        headers: {
            Authorization: authHeader,
            "Content-Type": "application/json",
        },
    };
    const data = { 
        name: user.name,
        last_name: user.last_name,
        document_number: user.document_number,
        phone_number: user.phone_number,
        email: user.email,
        accountant_profile: user.accountant_profile,
        terms: 'on',
        privacy: 'on',
        utm_source: user.utm_source,
        utm_medium: user.utm_medium,
        utm_campaign: user.utm_campaign,
    }
    
    return axios.post(api_url, { data }, config);
}
