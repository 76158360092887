import { ChangeEvent } from "react";

interface TextAreaProps {
  maxLength: number;
  valueTextArea: string;
  setValueTextArea: (value: string) => void;
}

export const TextArea: React.FC<TextAreaProps> = ({
  maxLength,
  valueTextArea,
  setValueTextArea,
}) => {
  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const inputText: string = event.target.value;
    if (inputText.length <= maxLength) {
      setValueTextArea(inputText);
    }
  };

  return (
    <div>
      <textarea
        placeholder="Mi empresa si tiene auto, etc."
        className="border border-[#868F9E] w-full rounded-lg h-44 px-3 py-2 text-sm"
        value={valueTextArea}
        onChange={handleChange}
        maxLength={maxLength}
      />
      <div className="text-right text-xs">
        {valueTextArea.length}/{maxLength} palabras
      </div>
    </div>
  );
};
