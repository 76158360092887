import logoIcon from "/assets/logo-contapro--regular.png?url";

function Headerauth() {
    return (
        <div className="border-b border-[#C7CADD]">
            <div className="container mx-auto px-5 sm:px-8 py-3 max-w-screen-xl">
                <img src={logoIcon} alt="Somos Contapro" />
            </div>
        </div>
    );
};

export default Headerauth;