import { yupResolver } from "@hookform/resolvers/yup";
import { ChevronLeftIcon } from "lucide-react";
import { useEffect } from "react"
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import { editCredentials } from "./edit-credentials-helper";
import { Button } from "@/components/ui/button";
import FormComponent from "@/business/FormComponent";
import { useBusinessStore } from "@/store/BusinessStore";
import { useDashboardStore } from "@/store/DashboardStore";

const editForm = Yup.object().shape({
    name: Yup.string().required('Ingrese el nombre de la empresa'),
    ruc: Yup.string().required('Ingrese el RUC'),
    username: Yup.string().required('Ingrese el usuario SOL')
        .length(8, 'El usuario SOL debe tener 8 caracteres'),
    passwordSOL: Yup.string().required('Ingrese su clave SOL')
})

function EditCredentials() {
    const navigate = useNavigate();
    const location = useLocation();
    const business = useBusinessStore((state) => state.business);
    const { editCredentials } = useDashboardStore( state => state)
    const { handleSubmit,
        register,
        formState: { isValid, errors },
        reset
    } = useForm<editCredentials>({
        resolver: yupResolver(editForm),
        mode: 'onBlur',
        defaultValues: {
            name: business.name,
            ruc: business.ruc
        }
    })
    useEffect(() => {
    }, [])
    const onEditar = (data: editCredentials) => {
        const body = {
            username: data.username,
            password: data.passwordSOL
        }
        editCredentials(body, business.uuid, reset)
        navigate(location.pathname, { replace: true });
    }
    return <>
        <Link to="/inicio" className="flex items-center gap-1">
            <ChevronLeftIcon className="w-4 h-4" />
            <span className="text-[#5D6075] text-sm f-secondary">Volver a listado de empresas</span>
        </Link><br />
        <h2 className="text-xl font-semibold">Editar Credenciales</h2>
        <p className="text-sm mt-3">Sólo edita estas credenciales si hubo un error al ingresarlas. De lo contrario, no podremos extraer la información para la declaración.</p>
        <div className="w-2/4">
            <form onSubmit={handleSubmit(onEditar)}>
                <FormComponent register={register} errors={errors} isEdit={true}/>
                <Button type="submit" className="mt-4" disabled={!isValid}>
                    Editar credenciales
                </Button>
            </form>
        </div>
        {/* <div className="w-2/4">
            <form onSubmit={handleSubmit(onEditar)}>
                <FormItem>
                    <Input {...register('name')} placeholder="Nombre de la empresa" variant={errors.name && 'error'} />
                </FormItem>
                <FormItem>
                    <Input {...register('ruc')} placeholder="RUC" />
                </FormItem>
                <p className="text-sm mt-3 mb-3">Información del portal SOL de la empresa</p>
                <FormItem>
                    <Input {...register('username')} placeholder="Usuario Portal SOL" />
                </FormItem>
                <FormItem>
                    <Input {...register('passwordSOL')} placeholder="Clave SOL" />
                </FormItem>
                <Button type="submit" className="mt-4" disabled={!isValid}>
                    Editar credenciales
                </Button>
            </form>
        </div> */}
    </>
}

export default EditCredentials