import { Outlet, Navigate } from "react-router-dom";
import { useAuthStore } from "./store/AuthStore";
import { useEffect } from "react";
import { useBusinessStore } from "./store/BusinessStore";

export const PrivateRoutes = () => {
  const userToken = useAuthStore((state) => state.accessToken);
  const getMe = useAuthStore((state) => state.getMe);

  useEffect(() => {
    if (userToken) {
      getMe().catch((_) => console.error("Error getting user data..."));
    }
  }, [userToken, getMe]);

  return userToken ? <Outlet /> : <Navigate to="/login" />;
  //return <Outlet />
};

export const PublicRoutes = () => {
  const userToken = useAuthStore((state) => state.accessToken);
  return userToken ? <Navigate to="/inicio" /> : <Outlet />;
};

export const BusinessDashboardGuard = () => {
  const business = useBusinessStore((state) => state.business);
  return business && business.uuid ? <Outlet /> : <Navigate to="/inicio" />;
};
