import GraphicCard from "./GraphicCard.tsx";
import { COLOR_FACTURAS, COLOR_BOLETAS, formatNumber, COLOR_NOTAS_CREDITO, COLOR_NOTAS_DEBITO } from "./helpers.ts";
import ProcessingDataCard from "./ProcessingDataCard.tsx";
import Card from '../shared/CardInfo.tsx';

function Sales({ sales }: { sales: Sales; businessInfo: Business; currencyCode?: string }) {
    const data = [
        {
            name: "Facturas",
            value: sales.porc_bills ?? 0,
            totalValue: sales.num_bills ?? 0,
            amountIGV: sales.igv_bills,
            color: COLOR_FACTURAS,
        },
        {
            name: "Boletas",
            value: sales.porc_tickets ?? 0,
            totalValue: sales.num_tickets ?? 0,
            amountIGV: sales.igv_tickets,
            color: COLOR_BOLETAS,
        },
        {
            name: "Notas de crédito",
            value: sales.num_credit_notes ?? 0,
            totalValue: sales.num_credit_notes ?? 0,
            amountIGV: sales.igv_credit_notes,
            color: COLOR_NOTAS_CREDITO,
        },
        {
            name: "Notas de débito",
            value: sales.num_debit_notes ?? 0,
            totalValue: sales.num_debit_notes ?? 0,
            amountIGV: sales.igv_debit_notes,
            color: COLOR_NOTAS_DEBITO,
        },
    ];

    

    return (
        <div className="mt-6 mb-4 lg:mb-3">
            <div className="flex flex-col-reverse md:flex-row gap-4 mb-4">
                <div className="w-full lg:w-3/5">
                    {sales.num_bills === 0 && sales.num_tickets === 0 ? (
                        <ProcessingDataCard
                            title="No hay ventas por mostrar"
                            subtitle="No hemos encontrado ventas en el periodo."
                        />
                    ) : (
                        <GraphicCard
                            title="Tipos de comprobantes emitidos"
                            direction="column"
                            showTitleIcon={false}
                            iconTitle={""}
                            data={data}
                            textCenterUpper={`s/ ${formatNumber(sales.igv_amount_total)}`}
                            textCenterLower="Monto de IGV"
                            cssClassWrapper="sm:flex gap-4 2xl:grid grid-cols-5"
                        >
                            <GraphicCard.CardInfo>
                                <div className="legend">
                                    <div className="legend-detail">
                                        {data.map((item, index) => (
                                            <div
                                                key={`card-${index}`}
                                                id={`legend_${index}_${item.name}`}
                                                className="border-none border-transparent">
                                                <div className="flex justify-between gap-2 mb-4">
                                                    <div className="text-left flex gap-2">
                                                        <div className="font-normal text-sm">
                                                            <div className="flex align-middle mb-1">
                                                                <svg
                                                                    className="margin-auto"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="12"
                                                                    height="12"
                                                                    viewBox="0 0 12 12"
                                                                    fill="none">
                                                                    <circle cx="6" cy="6" r="6" fill={item.color} />
                                                                </svg>
                                                                <p className="pl-2 text-xs font-semibold">{item.name}</p>
                                                            </div>
                                                            <p className="pl-5 text-xs font-normal">Monto de IGV</p>
                                                        </div>
                                                    </div>
                                                    <div className="text-right block">
                                                        <p className="text-xs font-bold mb-1">{item.totalValue}</p>
                                                        <p className="text-xs font-semibold">S/ {formatNumber(item.amountIGV)}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </GraphicCard.CardInfo>
                        </GraphicCard>
                    )}
                </div>
                <div className="w-full lg:w-2/5 hidden">
                    <Card className="bg-white mb-4">
                        <p className="font-bold text-xl text-[#15161C] mb-3">Ventas del mes</p>

                        {/*<Card.Body>
                            // TODO implementar cuando exista data
                            <p className="font-bold text-xl text-[#15161C] mb-3">Ventas del mes</p>
                            <div className="flex gap-2">
                                <span className="f-secondary font-normal text-2xl text-[#15161C]">s/ { formatNumber(0) }</span>
                                <span className="f-secondary text-lg font-medium text-[#37A500]">+50%</span>
                                <img src={ iconMetricGreen } alt="icon metric up"/>
                            </div>
                            <p className="f-secondary font-normal text-sm text-[#5D6075]">Importe total</p>
                        </Card.Body>*/ }
                    </Card>

                    <Card>
                        <p className="font-bold text-xl text-[#15161C] mb-4">Facturas emitidas</p>

                        {/*<Card.Body>
                            // TODO implementar cuando exista data
                            <p className="font-bold text-xl text-[#15161C] mb-4">Facturas emitidas</p>
                            <div className="flex gap-2 mb-1">
                                <span
                                    className="f-secondary font-normal text-2xl text-[#15161C]">s/ { formatNumber(0) }</span>
                                <span className="f-secondary text-lg font-medium text-[#37A500]">+50%</span>
                                <img src={ iconMetricGreen } alt="icon metric up"/>
                            </div>
                            <p className="f-secondary font-normal text-sm text-[#5D6075]">Importe total</p>
                        </Card.Body>*/ }
                    </Card>
                </div>
            </div>
        </div>
    );
}

export default Sales;
