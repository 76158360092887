import { ModalState } from "@/shared/Modal";
import { useState } from "react";
import ShowItemsModal from "./ShowItemsModal";
import { useDashboardStore } from "@/store/DashboardStore";

const ShowItemsRowCell = ({
  cpes,
  currentPage,
  pageSize,
}: {
  cpes: Cpes;
  currentPage: number;
  pageSize: number;
}) => {
  const [isModalOpenShowItems, setModalOpenShowItems] = useState<ModalState>(ModalState.CLOSE);
  const { fetchDashboardReportsObserved } = useDashboardStore((state) => state);
  const onClickShowItems = () => {
    setModalOpenShowItems(ModalState.OPEN);
  };

  const handleOnClickCloseModalShowItems = () => {
    if (isModalOpenShowItems === ModalState.DONE) {
      fetchDashboardReportsObserved(currentPage, pageSize);
    }
    setModalOpenShowItems(ModalState.CLOSE);
  };

  return (
    <>
      {cpes && cpes.items && cpes.items.length ? (
        <>
          <span
            className="text-blue-normal cursor-pointer font-bold"
            onClick={() => onClickShowItems()}
          >
            Ver items
          </span>
          <ShowItemsModal
            modalState={isModalOpenShowItems}
            setModalState={setModalOpenShowItems}
            closeModalCallback={() => handleOnClickCloseModalShowItems()}
            cpes={cpes}
          />
        </>
      ) : (
        <span className="text-gray-400 font-semibold">Ver items</span>
      )}
    </>
  );
};

export default ShowItemsRowCell;
