import { useDashboardStore } from "@/store/DashboardStore";
import { useAuthStore } from "@/store/AuthStore.tsx";
import { useEffect, useState } from "react";
import PlanTypeAlert from "../PlanTypeAlert.tsx";
import ModalVideoTutorial from "../ModalVideoTutorial.tsx";
import HeaderHome from "./components/HeaderHome.tsx";
import CreateBusinessButton from "./components/CreateBusinessButton.tsx";
import BusinessCard from "./components/BusinessCard.tsx";
import EmptyBusiness from "./components/EmptyBusiness.tsx";
import LoadingText from "./components/LoadingText.tsx";
import StatusDataModal from "./components/modal/StatusDataModal.tsx";
import { isAfterToday } from "./helpers/index.tsx";

enum contaproSubscription {
  free = 1,
  pay = 2,
}

function Dashboard() {
  const [businessSelected, setBusinessSelected] = useState<BusinessFilter | null>(null);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [isEmptyBusiness, setIsEmptyBusiness] = useState(false);
  const [isShowModalRegisterSuccess, setIsShowModalRegisterSuccess] = useState(false);
  const [searching, setSearching] = useState("");
  const {
    filters,
    fetchFiltersBusiness,
    resetReportsPerceptions,
    resetReportsObserved,
    resetDashboardData,
  } = useDashboardStore((state) => state);
  const { user } = useAuthStore((state) => state);

  const userHasFreeSubscription = user.subscription?.plan_type === contaproSubscription.free;
  const subscriptionNotFinished =
    user.subscription?.end_date && isAfterToday(user.subscription?.end_date);
  const userPlanType = userHasFreeSubscription ? "free" : "expired_plan";

  // se crea variable para filtrar las empresas por nombre
  const filteredBusinesses = filters?.businesses.filter((business) => {
    return business.name.toLowerCase().includes(searching.toLowerCase());
  });

  useEffect(() => {
    // Verificar si es la primera vez que hace login
    const isFirstLogin = localStorage.getItem("isFirstLogin");

    if (isFirstLogin && isFirstLogin === "true") {
      setIsShowModalRegisterSuccess(true);
    }

    resetReportsObserved();
    resetReportsPerceptions();
    resetDashboardData();

    fetchFiltersBusiness()
      .then((response) => {
        setIsEmptyBusiness(false);
        if (response && response.businesses.length === 0) {
          console.log("No businesses");
          setIsEmptyBusiness(true);
        }
      })
      .catch((error) => {
        if (error.statusText !== "canceled") {
          console.error("Filters couldn't fetch");
        }
      });

  }, []);

  return (
    <div className="px-6 py-8">
      {!isEmptyBusiness && <HeaderHome name={user.name} setSearching={setSearching} />}
      {filters.businesses?.length > 0 ? (
        <>
          {userHasFreeSubscription && subscriptionNotFinished && (
            <PlanTypeAlert planType={userPlanType} endDate={user.subscription?.end_date} />
          )}
          <div className="grid gap-6 grid-cols-1 md:grid-cols-2 2xl:grid-cols-4 mt-6 mb-16 lg:mb-0">
            {filteredBusinesses
              .sort((a, b) => (a.status > b.status ? -1 : 1))
              .map((business: BusinessFilter, i: number) => (
                <div key={`card_${i}`}>
                  <BusinessCard
                    index={i}
                    user={user}
                    business={business}
                    setBusinessSelected={setBusinessSelected}
                    setAlertOpen={setIsAlertOpen}
                  />
                </div>
              ))}
          </div>

          <div className="block mt-6 md:hidden fixed bottom-0 left-0 h-auto bg-white w-full p-4">
            <CreateBusinessButton />
          </div>
        </>
      ) : (
        !isEmptyBusiness && <LoadingText></LoadingText>
      )}

      {isEmptyBusiness && <EmptyBusiness />}

      {businessSelected && (
        <StatusDataModal
          businessSelected={businessSelected}
          open={isAlertOpen}
          onClose={() => setIsAlertOpen(false)}
        />
      )}

      {isShowModalRegisterSuccess && (
        <ModalVideoTutorial modalType="registerSuccess" showModal={isShowModalRegisterSuccess} />
      )}
    </div>
  );
}

export default Dashboard;
