import { create } from "zustand";

interface AppicationState {
    loadingTags: string[];
    showAccountantProfileSOLForm: boolean;
    addLoadingTag: (loadingTag: string) => void;
    removeLoadingTag: (loadingTag: string) => void;
    setShowAccountantProfileSOLForm: (showing: boolean) => void;
}

export const useApplicationStore = create<AppicationState>((set,get) => ({
    showAccountantProfileSOLForm: true,
    loadingTags: [],
    addLoadingTag: (loadingTag: string) => {
        const loadingTags = get().loadingTags

        if(!loadingTags.includes(loadingTag)){
            set({loadingTags : [...loadingTags, loadingTag]})
        }
    },
    removeLoadingTag: (loadingTag: string) => {
        const loadingTags = get().loadingTags

        if(loadingTags.includes(loadingTag)){
            set({loadingTags : loadingTags.filter(tag=> tag!== loadingTag)})
        }
    },
    setShowAccountantProfileSOLForm: (showing: boolean) => set({ showAccountantProfileSOLForm: showing }),
}));
