import { useDashboardStore } from "@/store/DashboardStore";
import { useEffect, useState } from "react";

export interface PaginationState {
  currentPage: number;
  pageSize: number;
  pageCoun?: number;
  totalItems?: number;
}

export default function usePagination() {
  const {
    tax_period ,
  } = useDashboardStore((state) => state.valuesFilterSelected);
  const [paginationState, setPaginationState] = useState<PaginationState>({
    currentPage: 1,
    pageSize: 10,
  });

  useEffect(()=> {
    setPaginationState({
      currentPage: 1,
      pageSize: 10,
    })
  }, [tax_period])

  const onChangePage = (page: number) => {
    setPaginationState((prev) => ({ ...prev, currentPage: page }));
  };

  const onChangePageSize = (pageSize: number) => {
    setPaginationState((prev) => ({ ...prev, pageSize, currentPage: 1 }));
  };

  return {
    ...paginationState,
    onChangePage,
    onChangePageSize,
    setPaginationState,
  };
}
