import { useState } from "react";
import modalImg from "/assets/ic_token_expired.svg?url";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "../store/AuthStore";


const ModalTokenExpired = ({ showModal }: { showModal: boolean }) => {
    const [ isShowModal, setIsShowModal ] = useState(showModal);
    const {logout, setAccessTokenExpired } = useAuthStore(state => state);
    const navigate = useNavigate();

    const onClickButtonLogin = () => {
        logout();
        setAccessTokenExpired(false);
        setIsShowModal(false);
        navigate('/login');
    };

    return (
        <div className={`fixed inset-0 flex items-center justify-center ${isShowModal ? 'flex' : 'hidden'}`}>

            <div className="fixed inset-0 bg-black opacity-50"></div>
            <div className="relative z-50 bg-white p-6 rounded-md shadow-md min-w-64 min-h-80 w-2/5 flex items-center justify-center">
                <div className="block">
                    <div className="flex flex-col w-full items-center justify-center rounded-full bg-transparent">
                        <div className="p-4">
                            <img src={modalImg} alt="Error" className="w-16 h-auto"/>
                        </div>
                        <div className="text-center">
                            <p className="text-xl font-medium text-gray-700">Tu sesión caducó</p>
                            <p className="text-sm">Vuelve a iniciar sesión para ver toda tu información.</p>
                            <button type="button" className="w-full sm:w-auto primary-button-2 py-2 px-4 mt-4" onClick={() => onClickButtonLogin()}>Iniciar sesión</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ModalTokenExpired;