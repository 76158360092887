import { ModalState } from "@/shared/Modal";
import { ValidateInvoiceFormProps } from "../models/validateInvoiceForm";
import { useState } from "react";
import { useDashboardStore } from "@/store/DashboardStore";
import { TextArea } from "./TextArea";

const ValidateOneInvoiceForm = ({
  cpes,
  setModalState,
  setIsButtonValidateClicked,
}: ValidateInvoiceFormProps) => {
  const [text, setText] = useState<string>("");
  const { validateReportObserved } = useDashboardStore((state) => state);

  const onClickValidateReport = async () => {
    try {
      setModalState(ModalState.LOADING);

      const body = [
        {
          code: cpes.code,
          reason: text,
        },
      ];

      await validateReportObserved(body);

      setModalState(ModalState.DONE);

      if (setIsButtonValidateClicked) {
        setIsButtonValidateClicked(false);
      }
      console.log("Aprobe report success");
    } catch {
      setModalState(ModalState.ERROR);
      console.error("Aprobe report error");
    }
  };

  return (
    <div className="text-left">
      <h3 className="text-sm font-semibold mb-1">Observación</h3>
      <p className="text-sm font-normal">{cpes.observation}</p>
      <hr className="my-3" />
      <h3 className="text-sm font-semibold mb-3">Indícanos la correción de la observación</h3>
      <div>
        <TextArea maxLength={100} valueTextArea={text} setValueTextArea={setText}></TextArea>
      </div>

      <div className="text-right mt-3">
        <button
          disabled={!text || text.trim().length === 0}
          className="flex-none rounded-[20px] w-full mb-3 sm:mb-0 sm:w-[200px] px-3 py-2 bg-stone-950 text-white"
          onClick={() => onClickValidateReport()}
        >
          Corregir factura
        </button>
      </div>
    </div>
  );
};

export default ValidateOneInvoiceForm;
