import { Link } from 'react-router-dom';
import { useDashboardStore } from "../../store/DashboardStore";
import { formatNumber } from "../helpers";
import { useEffect } from "react";
import Card from "../../shared/CardInfo";
import TablePerceptions from "./TablePerceptions.tsx";
import icLeftGray from "/assets/ic_angle_left_gray.svg?url";
import PaginationSizeSelect from '@/components/shared/pagination-size-select.tsx';
import usePagination from '@/hooks/use-pagination.ts';
import Pagination from '@/components/ui/pagination/pagination.tsx';
import FilterPeriodSelect from '@/components/shared/period-filter-select.tsx';

interface CardsHeaderProps {
    total_amount_perceptions: number;
    total_count: number;
}

const CardsHeader = ({ total_amount_perceptions, total_count}: CardsHeaderProps) => {
    const dataCards = [
        {
            ccsClass: 'bill-obs__card--1',
            label: 'Total de percepciones',
            amount: null,
            total: total_count
        },
        {
            ccsClass: 'bill-obs__card--2',
            label: 'Monto total de percepciones',
            amount: total_amount_perceptions,
            total: null
        },
    ];

    return (
        <div className="grid grid-cols-2 gap-3 md:flex md:justify-between md:gap-3 mt-5 max-w-xl">
            {dataCards.map((data, index) => (
                <Card key={`card-bill-observed-${index}`} className={`${data.ccsClass} h-full flex-[1_1_100%]`}>
                    <Card.Body>
                        <p className="font-semibold text-sm f-secondary mb-2">{data.label}</p>

                        {data.amount || data.amount === 0 ? (
                            <p className="text-xl f-secondary font-normal f-secondary">s/ {formatNumber(data.amount)}</p>
                        ) : (
                            <p className="text-xl f-secondary font-normal f-secondary">{data.total}</p>
                        )}
                    </Card.Body>
                </Card>
            ))}
        </div>
    );
}

function Perceptions() {
    const {  reportsPurchasePerceptions, valuesFilterSelected : {tax_period} , fetchReportPerceptions } = useDashboardStore((state) => state);
	const { pageSize, onChangePageSize, currentPage, onChangePage } = usePagination()

    useEffect(() => {
        fetchReportPerceptions(currentPage,pageSize)
            .then(() => console.info("Dashboard reports perceptions fetched"))
            .catch((_) => console.error("Dashboard reports perceptions couldn't fetch"));
    }, [tax_period, pageSize, currentPage]);

    return (
        <>
            <Link to="/inicio" className="flex items-center gap-1">
                <img src={icLeftGray} alt="Volver"/>
                <span className="text-[#5D6075] text-sm f-secondary">Volver a listado de empresas</span>
            </Link>
            <div className="sm:flex items-center gap-3 justify-between mt-4 lg:mt-6">
                <h2 className="bill-obs-detail__title">Percepciones</h2>
                <div className="min-[360px]:flex gap-4 justify-center align-middle mt-4 sm:mt-0">
                   
                    <FilterPeriodSelect />
                    {/*
                    // TODO: Implement download TXT
                    <button
                        type="button"
                        className="flex-none bg-primary-black rounded-[40px] px-5 py-2 font-medium text-sm text-white"
                    >Descargar TXT</button>*/}
                </div>
            </div>

            {
                reportsPurchasePerceptions &&
                <CardsHeader
                    total_count={reportsPurchasePerceptions.summary.total_items}
                    total_amount_perceptions={reportsPurchasePerceptions.summary.total_amount_perceptions}
                />
            }

            {
                reportsPurchasePerceptions?.data?.vouchers && reportsPurchasePerceptions?.data?.vouchers.length > 0
                    ? 
                    <> 
                        <div className="my-4 flex justify-end">
						    <PaginationSizeSelect pageSize={pageSize} onChange={onChangePageSize} />
					    </div>
                        <TablePerceptions cpesPerceptions={reportsPurchasePerceptions.data.vouchers}/>
                        <Pagination currentPage={currentPage} pageCount={reportsPurchasePerceptions.data.pagination.total_pages} onChangePage={onChangePage} />
                    </>
                    : <p className="my-5 text-sm font-normal text-gray-500">No hay data para el periodo seleccionado</p>
            }   
        </>
    );
}

export default Perceptions;
