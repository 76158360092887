import { Cell, Pie, PieChart } from "recharts";
import Card from '../shared/CardInfo.tsx';

const RADIAN = Math.PI / 180;

interface DataGraphic {
    name: string;
    value: number;
    totalValue: number;
    color: string;
    amount?: string;
}

interface GraphicCardProps {
    title: string;
    data: DataGraphic[];
    showTitleIcon: boolean;
    iconTitle: string;
    direction: "row" | "column",
    cssClassWrapper?: string;
    textCenterUpper?: string;
    textCenterLower?: string;
    children?: React.ReactNode;
}

const CardInfo: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return <div>{children}</div>;
};

function makeid(length: number) {
    let result = "";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;

    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}

function GraphicCard({
    title,
    data,
    showTitleIcon = false,
    iconTitle = '',
    direction = "column",
    cssClassWrapper = '',
    textCenterUpper,
    textCenterLower,
    children,
}: GraphicCardProps) {
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, index }: any) => {
        const value = data[index]?.value;

        if (value === 0 && !value) {
            return null; // Si el valor es cero, no renderizar etiqueta
        }

        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;

        // Posición central para el label de 100%
        const centerX = cx + radius * Math.cos(-midAngle * RADIAN);
        const centerY = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text
                x={centerX}
                y={centerY}
                style={{ border: "none", borderWidth: "0" }}
                fill="transparent"
                textAnchor="middle"
                stroke="none"
                dominantBaseline="middle"></text>
        );
    };

    const labelCenter = ({ cx, cy, midAngle, innerRadius, outerRadius, index }: any) => {
        const value = data[index]?.value;

        if (value === 0 && !value) {
            return null; // Si el valor es cero, no renderizar etiqueta
        }

        const radius = innerRadius + (outerRadius - innerRadius) * 0.9;

        // Posición central para el label de 100%
        const centerX = cx + radius * Math.cos(-midAngle * RADIAN);
        const centerY = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <g key={makeid(10)}>
                {textCenterUpper && (
                    <g key={makeid(10)}>
                        <text
                            x={centerX + 10}
                            y={centerY - 5}
                            fill="#000000"
                            textAnchor="center"
                            className="text-sm font-semibold"
                            stroke="none"
                            dominantBaseline="middle">
                            {textCenterUpper}
                        </text>
                    </g>
                )}
                {textCenterLower && (
                    <g key={makeid(10)}>
                        <text
                            x={centerX + 15}
                            y={centerY + 20}
                            fill="#000000"
                            textAnchor="center"
                            stroke="none"
                            className="text-xs"
                            dominantBaseline="middle">
                            {textCenterLower}
                        </text>
                    </g>
                )}
            </g>
        );
    };

    const outerRadius = 85;
    const innerRadius = outerRadius - 25;

    let classWrapper = "";

    if (direction === "column") {
        classWrapper = "flex gap-1 min-[1300px]:gap-[3.7em] items-center justify-between flex-col";
    } else {
        classWrapper = "flex gap-1 min-[1300px]:gap-[3.7em] items-center justify-between flex-row flex-row-reverse max-lg:flex-col"
    }

    return (
        <Card className="bg-white">
            <Card.Body>
                <div className="flex items-center gap-3 mb-4">
                    { showTitleIcon &&
                        <div
                            className="w-14 h-14 flex items-center justify-center rounded-full bg-salmon">
                            <img src={ iconTitle } alt="Icono tarjeta"/>
                        </div>
                    }
                    <h3 className="text-xl font-semibold">{ title }</h3>
                </div>
                <div className={ cssClassWrapper !== '' ? cssClassWrapper : classWrapper }>
                <div id="pie_chart" className={ cssClassWrapper.includes('grid') ? 'col-span-2 w-full' : 'w-full' }>
                        <PieChart key={ makeid(10) } width={ 170 } height={ 170 } className="mx-auto">
                            <Pie
                                nameKey="key"
                                data={ data }
                                cx="50%"
                                cy="50%"
                                labelLine={ false }
                                label={ renderCustomizedLabel }
                                outerRadius={ outerRadius }
                                innerRadius={ innerRadius }
                                fill="#c0c0c0"
                                enableBackground={ 1 }
                                dataKey="value">
                                { data.map((item, index) => (
                                    <Cell key={ `cell-${ index }` } fill={ item.color } stroke="none"/>
                                )) }
                            </Pie>
                            { (textCenterUpper || textCenterLower) && (
                                <Pie
                                    nameKey="key"
                                    id="center_label"
                                    data={ [ { name: "monto", value: 100 } ] }
                                    dataKey="value"
                                    cx="50%"
                                    cy="50%"
                                    outerRadius={ innerRadius }
                                    fill="#fff"
                                    labelLine={ false }
                                    label={ labelCenter }
                                    stroke="none"></Pie>
                            ) }
                        </PieChart>
                    </div>
                    <div id="extra_info"
                         className={ cssClassWrapper.includes('grid') ? 'col-span-3 w-full' : 'w-full' }>
                        { children }
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
}

GraphicCard.CardInfo = CardInfo;

export default GraphicCard;
