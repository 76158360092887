import { useRouteError } from "react-router-dom";
import ErrorPageImage from '../../public/assets/sp_window_close.svg' 

export default function ErrorPage() {
  const error = useRouteError() as any;
  console.log('Error:', error);

  return (
    <div id="error-page" className="h-screen flex flex-col items-center justify-center text-center gap-4 px-2">
      <img src={ErrorPageImage} className="w-28" />
      <h2 className="text-2xl text-primary-black font-bold">¡Oops! Algo salió mal</h2>
      <p className="text-lg text-neutral-normal">Por favor, vuelve a ingresar a Contapro más tarde</p>
    </div>
  );
}
