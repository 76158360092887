import iconWhatsapp from "/assets/ic_social_whatsapp.svg?url";

export function WhatsappLink() {
  return (
    <a className="text-sm text-blue-normal hover:underline" href="https://wa.me/51995144727" target="_blank">
      <img src={iconWhatsapp} alt="Icono" className="inline-block mr-1" />
      995 144 727
    </a>
  );
}
