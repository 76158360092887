import { formatNumber } from "@/dashboard/helpers";
import Card from "@/shared/CardInfo";

const CardsHeader = ({
  reportsObserved,
  reportsRejected,
  currentTab,
}: {
  reportsObserved: ReportsObserved;
  reportsRejected: ReportsObserved;
  currentTab: string;
}) => {
  const dataCards = [
    {
      ccsClass: "bill-obs__card--1 bg-surface-200",
      label: "Monto total de IGV",
      amount:
        currentTab == "pending"
          ? reportsObserved.summary.total_igv_amount
          : reportsRejected.summary.total_igv_amount,
      total: null,
    },
    {
      ccsClass: "bill-obs__card--2 bg-surface-100",
      label: "Total de observaciones",
      amount: null,
      total:
        currentTab == "pending"
          ? reportsObserved.summary.total_items
          : reportsRejected.summary.total_items,
    },
  ];

  return (
    <div className="grid grid-cols-2 gap-3 md:flex md:justify-between md:gap-3 mt-5 max-w-xl">
      {dataCards.map((data, index) => (
        <Card
          key={`card-bill-observed-${index}`}
          className={`${data.ccsClass} h-full flex-[1_1_100%]`}
        >
          <Card.Body>
            <p className="font-semibold text-sm f-secondary mb-2">{data.label}</p>

            {data.amount || data.amount === 0 ? (
              <p className="text-xl f-secondary font-normal f-secondary">
                s/ {formatNumber(data.amount)}
              </p>
            ) : (
              <p className="text-xl f-secondary font-normal f-secondary">{data.total}</p>
            )}
          </Card.Body>
        </Card>
      ))}
    </div>
  );
};

export default CardsHeader;
