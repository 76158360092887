import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import useDownloadStatusMessage from "../hooks/useDownloadStatusMessages";
import { StatusValues } from "../models/status-download";
import Modal, { ModalState } from "@/shared/Modal";
import { useApplicationStore } from "@/store/ApplicationStore";
import useLoadImage from "../hooks/useLoadImage";
import useLoadPdf  from "../hooks/useLoadPDF";
import { useEffect, useRef, useState } from "react";

interface DownloadAlertModalProps {
  status: StatusValues;
  open: boolean;
  onClose(): void;
}

interface PreviewFileModalProps {
  fileUrl: string;
  modalState: ModalState;
	closeModalCallback: () => void;
}

export function DownloadAlertModal({ status, open, onClose }: DownloadAlertModalProps) {
  const { title, description, icon } = useDownloadStatusMessage(status);

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader className="text-center items-center gap-4">
          <img src={icon} className="w-16 h-16" />
          <DialogTitle>{title}</DialogTitle>
          <DialogDescription>{description}</DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
}

export function PreviewFileModal({ fileUrl, modalState, closeModalCallback }: PreviewFileModalProps) {
  const { addLoadingTag, removeLoadingTag } = useApplicationStore();

  const isImage = (url: string) => /\.jpeg$/i.test(url);
  const isPdf = (url: string) => /\.pdf$/i.test(url);

  const { isImageLoaded } = useLoadImage(fileUrl, modalState, addLoadingTag, removeLoadingTag);
  const { isPdfLoaded, base64Pdf } = useLoadPdf(fileUrl, modalState, addLoadingTag, removeLoadingTag);

  const [pdfUrl, setPdfUrl] = useState<string | null>(null);

  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
  const imageRef = useRef<HTMLImageElement>(null); 
  const handleImageLoad = () => {
    if (imageRef.current) {
      const { naturalWidth, naturalHeight } = imageRef.current;
      setImageDimensions({ width: naturalWidth, height: naturalHeight });
    }
  };

  useEffect(() => {
    if (isPdf(fileUrl) && base64Pdf) {
      const byteCharacters = atob(base64Pdf);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      const blobUrl = URL.createObjectURL(blob);
      setPdfUrl(blobUrl);
    }
  }, [fileUrl, base64Pdf]);

  const imageWidth = imageDimensions.height > 900 ? 'size-4/6' : 'w-full';

  let imageContent;
  
  if (isImage(fileUrl)) {
    imageContent = isImageLoaded && (
      <img
        ref={imageRef}
        src={fileUrl}
        alt="imagen de comprobante"
        className={`p-3 object-contain ${imageWidth}`}
        onLoad={handleImageLoad}
      />
    );
  } else if (isPdf(fileUrl) && pdfUrl) {
    imageContent = isPdfLoaded && (
      <iframe 
        src={`${pdfUrl}#toolbar=0&navpanes=0`}
        width="100%" 
        height="560px"
      />
    );
  }

  return (
    (isImageLoaded || isPdfLoaded) && modalState === ModalState.OPEN && (
      <Modal
        modalState={modalState}
        typeModal="generic"
        modalWidth="w-3/6"
        showAlertIcon={false}
        onClose={closeModalCallback}
      >
        {imageContent}
      </Modal>
    )
  );
}

