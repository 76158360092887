import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDashboardStore } from '../../store/DashboardStore.tsx';
import React, { useEffect, useState } from 'react';
import { descendingComparator } from '../helpers.ts';
import { Tab, TabContent, TabContents, TabLayout, Tabs } from '../../shared/TabLayout.tsx';
import PurchasePDT from './PurchasePDT.tsx';
import SalesPDT from './SalesPDT.tsx';
import { ChevronLeftIcon } from 'lucide-react';

const FilterPeriod = (
	{   filters,
		selectedPeriod,
		setSelectedPeriod
	}: {
		filters: Filters,
		selectedPeriod: string;
		setSelectedPeriod: (period: string) => void;
	}) => {
	const valuesSelected = useDashboardStore((state) => state.valuesFilterSelected);
	const setValuesFilterSelected = useDashboardStore((state) => state.setValuesFilterSelected);
	const fetchDasboardReportsObserved = useDashboardStore((state) =>state.fetchDashboardData);

	const fetchDashboardReports = () => {
		fetchDasboardReportsObserved()
			.then(() => console.info("Dashboard reports fetched"))
			.catch((_) => console.error("Dashboard reports couldn't fetch"));
	};
	const handleTaxPeriodChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		setValuesFilterSelected({
			...valuesSelected,
			tax_period: e.target.value,
		});

		setSelectedPeriod(e.target.value);
		fetchDashboardReports();
	};

	return (
		<div className="relative min-w-40">
			<select
				onChange={handleTaxPeriodChange}
				value={selectedPeriod}
				id="period"
				className="business__select w-full text-sm appearance-none rounded-[16px] cursor-pointer focus:border-1 focus:placeholder-gray-200 focus:bg-white focus:border-gray-600 focus:outline-none">
				{filters.tax_periods?.sort(descendingComparator).map((period: string, i: number) => (
					<option value={period} key={`opt-period-${i}`}>
						{period}
					</option>
				))}
			</select>
			<div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
				<svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
					<path d="M10 12l-6-6-1.414 1.414L10 14.828l7.414-7.414L16 6z" />
				</svg>
			</div>
		</div>
	);
};

function ReportPDT () {
	const navigate = useNavigate();
	const location = useLocation();
	const { ruc, businessName } = location.state || {};
	const { filters, valuesFilterSelected, dashboardData, fetchDashboardData } = useDashboardStore((state) => state);
	const [selectedPeriod, setSelectedPeriod] = useState('');

	useEffect(() => {
		setSelectedPeriod(valuesFilterSelected.tax_period);
		if (!ruc || !businessName) {
			navigate('/inicio');
		}
		fetchDashboardData()
			.then(() => console.info('Dashboard fetched'))
			.catch((_) => {
				console.error('Dashboard data couldn\'t fetch');
			});
	}, [ navigate, ruc, businessName ]);

	return (
		<div>
			<Link to="/inicio" className="flex items-center text-neutral-normal gap-1">
				<ChevronLeftIcon className="w-4 h-4" />
				<span className="text-sm f-secondary">Volver a listado de empresas</span>
			</Link>

			<div className="min-[360px]:flex items-center gap-3 justify-between mt-4 lg:mt-6">
				<h2 className="bill-obs-detail__title">Informe PDT</h2>
				<FilterPeriod
					filters={ filters }
					selectedPeriod={ selectedPeriod }
					setSelectedPeriod={ setSelectedPeriod }
				/>
			</div>

			{
				dashboardData ? (
					<TabLayout initialTab="compras">
						<Tabs>
							<Tab name="compras"
							     tagAnalytics="">Compras</Tab>
							<Tab name="ventas"
							     tagAnalytics="">Ventas</Tab>
						</Tabs>
						<TabContents>
							<TabContent name="compras">
								<PurchasePDT
									purchase={dashboardData.purchase}
									sum_perception={dashboardData.sum_perception}
									sum_retention={dashboardData.sum_retention}
									businessInfo={{ ruc, businessName }}
								/>
							</TabContent>
							<TabContent name="ventas">
								<SalesPDT
									sales={dashboardData.sales}
								/>
							</TabContent>
						</TabContents>
					</TabLayout>
				) : (
					<p className="my-5 text-sm font-normal text-gray-500">No hay data para el periodo seleccionado</p>
				)
			}
		</div>
	);
}

export default ReportPDT;
