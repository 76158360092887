import CardLarge from '../../shared/CardLarge.tsx';
import CopyAmountButton from './Button.tsx';

function SalesPDT({ sales }: { sales: Sales }) {
	return (
		<div className="mt-5">
			<h2 className="text-xl font-semibold text-primary-black mb-3">Resumen IGV</h2>

			<div className="flex flex-col md:flex-row justify-between gap-5 pb-4">
				<div className="flex-none h-auto max-sm:w-full">
					<CardLarge title="Importe no gravado">
						<p className="text-sm f-secondary font-semibold text-neutral-normal mt-3">Monto total</p>
						<CopyAmountButton amount={sales.non_taxable_amount} />
					</CardLarge>
				</div>
				<div className="h-auto w-1/2 max-md:w-full">
					<CardLarge title="IGV 10%">
						<div className="flex gap-5 mt-3">
							<div>
								<p className="text-sm f-secondary font-semibold text-neutral-normal">Base Imponible</p>
								<p className="text-2xl f-secondary text-primary-black">
									<CopyAmountButton amount={sales.igv_10_taxable_amount} />
								</p>
							</div>
							<div>
								<p className="text-sm f-secondary font-semibold text-neutral-normal">Monto de IGV</p>
								<CopyAmountButton amount={sales.igv_10_amount} />
							</div>
						</div>
					</CardLarge>
				</div>
				<div className="h-auto w-1/2 max-md:w-full">
					<CardLarge title="IGV 18%">
						<div className="flex gap-5 mt-3">
							<div>
								<p className="text-sm f-secondary font-semibold text-neutral-normal">Base Imponible</p>
								<CopyAmountButton amount={sales.igv_18_taxable_amount} />
							</div>
							<div>
								<p className="text-sm f-secondary font-semibold text-neutral-normal">Monto de IGV</p>
								<CopyAmountButton amount={sales.igv_18_amount} />
							</div>
						</div>
					</CardLarge>
				</div>
			</div>

		</div>
	);
}

export default SalesPDT;
